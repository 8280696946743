import { all, call, put, takeLatest } from 'redux-saga/effects';

import { Axios } from '../../api/axios';
import { getSimplifiedError } from '../../utils/error';
import { toast } from 'react-toastify';

import {
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USERS_ERROR,

  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_ERROR,

  GET_USER_DATA_REQUEST,
  GET_USER_DATA_SUCCESS,
  GET_USER_DATA_ERROR,

  GET_USER_PERCENTAGE_REQUEST,
  GET_USER_PERCENTAGE_SUCCESS,
  GET_USER_PERCENTAGE_ERROR,

  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,

  GET_TRAINERS_REQUEST,
  GET_TRAINERS_SUCCESS,
  GET_TRAINERS_ERROR,
} from '../reducers/UsersReducer';



async function getUsers(search) {
  let searchKey = search ? search : '';
  return await Axios.get(`/all-user-detail/get_all_user/?user_name=${searchKey}`);
}

function* handleGetUsers({ payload }) {
  try {
    const response = yield call(getUsers, payload);
    if (response) {
      yield put({
        type: GET_USERS_SUCCESS,
        data: response.all_user_detail,
      });
      /* yield put({
        type: RESET_FLAGS_QUIZZ,
        payload: { blockType: 'getUsers' },
      }); */
    }
  } catch (error) {
    yield put({
      type: GET_USERS_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

async function getUser(payload) {
  return await Axios.get(`/user/${payload}/`);
}

function* handleGetUser({ payload }) {
  try {
    const response = yield call(getUser, payload);
    if (response) {
      yield put({
        type: GET_USER_SUCCESS,
        data: response,
      });
    }
  } catch (error) {
    yield put({
      type: GET_USER_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

async function getUserData(payload) {
  return await Axios.get(`/user-data/?user_id=${payload}`);
}

function* handleGetUserData({ payload }) {
  try {
    const response = yield call(getUserData, payload);
    if (response) {
      yield put({
        type: GET_USER_DATA_SUCCESS,
        data: response,
      });
    }
  } catch (error) {
    yield put({
      type: GET_USER_DATA_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

async function getUserPercentage(payload) {
  return await Axios.get(`/percentage/?user_id=${payload}`);
}

function* handleGetUserPercentage({ payload }) {
  try {
    const response = yield call(getUserPercentage, payload);
    if (response) {
      yield put({
        type: GET_USER_PERCENTAGE_SUCCESS,
        data: response,
      });
    }
  } catch (error) {
    yield put({
      type: GET_USER_PERCENTAGE_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

async function updateUser({id, data}) {
  console.log(data)
  return await Axios.patch(`/user/${id}/`, data);
}

function* handleUpdateUser({ payload }) {
  try {
    const response = yield call(updateUser, payload);
    if (response) {
      yield put({
        type: UPDATE_USER_SUCCESS,
        data: response
      });
    }
  } catch (error) {
    yield put({
      type: UPDATE_USER_ERROR,
      error: getSimplifiedError(error)
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

async function getTrainers() {
  return await Axios.get(`/trainer/`);
}

function* handleGetTrainers() {
  try {
    const response = yield call(getTrainers);
    if (response) {
      yield put({
        type: GET_TRAINERS_SUCCESS,
        data: response,
      });
    }
  } catch (error) {
    yield put({
      type: GET_TRAINERS_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

export default all([
  takeLatest(GET_USERS_REQUEST, handleGetUsers),
  takeLatest(GET_USER_REQUEST, handleGetUser),
  takeLatest(GET_USER_DATA_REQUEST, handleGetUserData),
  takeLatest(GET_USER_PERCENTAGE_REQUEST, handleGetUserPercentage),
  takeLatest(UPDATE_USER_REQUEST, handleUpdateUser),
  takeLatest(GET_TRAINERS_REQUEST, handleGetTrainers)
]);
