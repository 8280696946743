import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import NavInformation from '../../components/NavInformation/NavInformation';
import NewUsers from '../../components/NewUsers/NewUsers';
import Main from '../../components/Main/Main';

import Loader from '../../components/Loader/Loader';

import {
  getUsers
} from '../../redux/actions/UsersAction';

import {
  getPlansStatistic, getTotalData
} from '../../redux/actions/StatisticsAction';

export default function Dashboard() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getPlansStatistic());
    dispatch(getTotalData());
  }, []);

  const {
    getUsers: userData,
  } = useSelector(({ users }) => users);

  const {
    getPlansStatistic: plansStats
  } = useSelector(({ statistics }) => statistics);

  useEffect(() => {
    if (userData.success && plansStats.success) {
      setLoading(false);
    }
  }, [userData, plansStats.data]);

  return (
    <div>
      <NavInformation />

      { loading
        ? <Loader className="fullPageLoader" />
        : (
          <>
            <NewUsers users={userData.data.filter(user => user.new_user)} />
            <Main />
          </>
      )}
    </div>
  );
}
