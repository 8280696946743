import React from 'react';
import './defaultLightBox.css';
const DefaultLightBox = (props) => {
  const classProps = props.className || '';
  return (
    <>
      <div className={`defaultLightBox ${classProps}`}>{props.children}</div>
    </>
  );
};
export default DefaultLightBox;
