import React, { useState } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Accordion from 'react-bootstrap/Accordion';

import CustomToggleExercises from './CustomToggleExercises';
import VideoLibraryLevelTwo from './VideoLibraryLevelTwo';

import InputTitle from '../../UI/InputTitle';
import AddExercise from './AddExercise';

const Category = ({ data, trainers, index, activeKey, setActiveKey, onChange, onDelete, onAddExercise, onEditExercise, onDeleteExercise }) => {
  const [value, setValue] = useState(data.name);

  const onChangeHandle = event => {
    setValue(event.target.value);
  }

  const onBlurHandle = () => {
    if (data.name !== value)
      onChange(data.id, value);
  }

  const onKeyDownHandle = event => {
    if (event.keyCode === 13 && data.value !== value)
      onChange(data.id, value);
  }

  const onDeleteHandle = () => {
    onDelete(data.id);
  }

  return (
    <Card className={`border-0`} key={data.id}>
      <Card.Header className={`p-0 videoLevelOne`}>
        <CustomToggleExercises setActiveKey={setActiveKey} eventKey={index} activeKey={activeKey}>
          &nbsp; {data.name}
        </CustomToggleExercises>
        <DropdownButton title="" drop={`start`} className={`menuDots`}>
          <Dropdown.Item href="#" onClick={onDeleteHandle}>Delete category</Dropdown.Item>
        </DropdownButton>
      </Card.Header>

      <Accordion.Collapse eventKey={index}>
        <Accordion defaultActiveKey="0" className={`borderBottom`} activeKey={activeKey}>
          <Card className={`border-0 VideoLevelTwo`}>
            <Card.Header className={`py-0`}>
              <Row className={`inputVideoWrapperWidth`}>
                <Col md={8} className={`align-item-center d-flex`}>
                  <InputTitle className={`me-2`}>Category Name</InputTitle>
                  <Form.Control className="flex-grow-1 w-auto" type="text" value={value} onChange={onChangeHandle} onBlur={onBlurHandle} onKeyDown={onKeyDownHandle} placeholder="Enter category name" />
                </Col>
              </Row>
            </Card.Header>
          </Card>

          <hr className="my-0" />

          {data.exercises.map((exercise, index) =>
            <VideoLibraryLevelTwo
              exercise={exercise}
              trainers={trainers}
              index={index}
              onChange={onChange}
              onEdit={onEditExercise}
              onDelete={onDeleteExercise}
            />
          )}

          <AddExercise category={data.id} onSubmit={onAddExercise} />
        </Accordion>
      </Accordion.Collapse>
    </Card>
  )
}

export default Category;
