import * as React from 'react';

function DownIcon(props) {
  return (
    <svg width={30} height={30} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x={30} width={30} height={30} rx={15} transform="rotate(90 30 0)" fill="#C7922C" />
      <path
        d="M20.295 8.705a.998.998 0 00-1.41-.001l-3.179 3.171a1 1 0 01-1.412 0l-3.179-3.171a.998.998 0 00-1.41 1.411l4.588 4.588a1 1 0 001.414 0l4.588-4.588a.998.998 0 000-1.41zM20.295 15.705a.998.998 0 00-1.41-.001l-3.179 3.171a1 1 0 01-1.412 0l-3.179-3.171a.998.998 0 00-1.41 1.411l4.588 4.588a1 1 0 001.414 0l4.588-4.588a.998.998 0 000-1.41z"
        fill="#fff"
      />
    </svg>
  );
}

export default DownIcon;
