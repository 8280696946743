import React from 'react';

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import CurrentProgress from './CurrentProgress';
import UserInformation from './UserInformation';

import './style.scss';

const UserProfileRightContent = ({ user, userData, userPercentage, onUpdate }) => {
  return (
    <div className="userProfileRightContent">
      <Tabs defaultActiveKey="userInfo" id="user-profile-tab" className="tabList">
        <Tab eventKey="userInfo" title="user information">
          <UserInformation user={user} onUpdate={onUpdate} />
        </Tab>
        <Tab eventKey="progress" title="Current progress">
          <CurrentProgress userData={userData} userPercentage={userPercentage} />
        </Tab>
      </Tabs>
    </div>
  );
};

export default UserProfileRightContent;
