import React from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Form from 'react-bootstrap/Form';
import MovementWorkouts from './MovementWorkouts/MovementWorkouts';
import PlansUsers from './PlansUsers/PlansUsers';
import '../Plans.scss';
import { useSelector } from 'react-redux';
import Loader from '../../../Loader/Loader';

const Movement = ({ userTypeId }) => {
  const {
    movementWorkoutLevel: { loading },
  } = useSelector(({ plan }) => plan);
  return (
    <>
      {loading ? (
        <div className="text-center borderBottom">
          <Loader className="smallLoader" />
        </div>
      ) : (
        <div className="addActionUi">
          <Tabs defaultActiveKey="workouts" id="exercisePlans" className="titleTab">
            <Tab eventKey="workouts" title="workouts">
              <MovementWorkouts userTypeId={userTypeId} />
            </Tab>
            <Tab eventKey="users" title="users">
              <PlansUsers />
            </Tab>
            <Tab eventKey="plan" title="plan description">
              <div className="descriptionBox">
                <p>My black movement description for this plan</p>
                <Form.Control as="textarea" style={{ height: '100px' }} />
              </div>
            </Tab>
          </Tabs>
        </div>
      )}
    </>
  );
};
export default Movement;
