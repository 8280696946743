import React, { useState, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
import { useFirestore } from 'react-redux-firebase';
import { nanoid } from 'nanoid';

import DefaultLightBox from '../../UI/DefaultLightBox';

import Card from 'react-bootstrap/Card';
import UserFeedback from './UserFeedback';

import placeholder from '../../../assets/images/placeholder1.png';
import './usersMessage.scss';

const UsersMessage = ({ users, chatID, selectedUserId }) => {
  const firestore = useFirestore();
  const [messages, setMessages] = useState([]);

  useLayoutEffect(() => {
    if (!chatID)
      return;

    return firestore
      .collection(`chats/${chatID}/messages`)
      .orderBy('createdAt', 'asc')
      .onSnapshot(snapshot => setMessages(snapshot.docs.map(doc => doc.data())));
  }, [chatID]);

  const primaryUser = users.find(user => user.user_id == 9);
  const secondaryUser = users.find(user => user.user_id == selectedUserId);

  const sendMessage = async message => {
    if (!message.length)
      return false;

    // Update existing
    if (chatID) {
      firestore
        .collection(`users/${9}/chats/`)
        .doc(chatID)
        .update({
          message,
          messageType: 'text',
          updatedAt: firestore.FieldValue.serverTimestamp(),
          unReadMessagesCount: 0
        });

      const unReadMessagesCount = (await firestore
        .collection(`users/${selectedUserId}/chats/`)
        .doc(chatID)
        .get())
          .data()
          .unReadMessagesCount;

      firestore
        .collection(`users/${selectedUserId}/chats/`)
        .doc(chatID)
        .update({
          message,
          messageType: 'text',
          updatedAt: firestore.FieldValue.serverTimestamp(),
          unReadMessagesCount: unReadMessagesCount + 1
        });

      firestore
        .collection(`chats/${chatID}/messages`)
        .add({
          message,
          messageType: "text",
          createdAt: firestore.FieldValue.serverTimestamp(),
          sender: 9,
          receiver: selectedUserId
      });
    }
    // Create new
    else {
      const newID = nanoid(20);

      await firestore
        .collection(`users/${9}/chats`)
        .doc(newID)
        .set({
          matchID: selectedUserId,
          createdAt: firestore.FieldValue.serverTimestamp(),
          updatedAt: firestore.FieldValue.serverTimestamp(),
          message,
          messageType: 'text',
          unReadMessagesCount: 0,
          secondaryUser: {
            userID: selectedUserId,
            userName: `${secondaryUser.first_name} ${secondaryUser.last_name}`,
            userImage: secondaryUser.image || ''
          }
      });

      await firestore
        .collection(`users/${selectedUserId}/chats`)
        .doc(newID)
        .set({
          matchID: 9,
          createdAt: firestore.FieldValue.serverTimestamp(),
          updatedAt: firestore.FieldValue.serverTimestamp(),
          message,
          messageType: 'text',
          unReadMessagesCount: 1,
          secondaryUser: {
            userID: 9,
            userName: 'blaqueadmin',
            userImage: ''
          }
      });

      firestore
        .collection(`chats/${newID}/messages`)
        .add({
          message,
          messageType: "text",
          createdAt: firestore.FieldValue.serverTimestamp(),
          sender: 9,
          receiver: selectedUserId
      });
    }

    /* firestore
      .collection(path)
      .add({
        message,
        messageType: "text",
        createdAt: firestore.FieldValue.serverTimestamp(),
        sender: 9,
        receiver: selectedUserId
    }); */

    /* firestore
      .collection(`users/${9}/chats/`)
      .doc(chatID)
      .update({
        message,
        messageType: 'text',
        unReadMessagesCount: 0
      }); */

    /* firestore
      .doc(`users/${selectedUserId}/chats/${chatID}`)
      .update({
        message,
        messageType: 'text',
        unReadMessagesCount: 1
      }); */

    // setMessage('')
  }

  return (
    <DefaultLightBox className="userMessageWrapper">
      <div className="p-3 relative align-item-center pb-2">
        <div className="userInfoWrapper">
          <img src={secondaryUser.image || placeholder} width="40" height="40" />
          <span className="userName">{secondaryUser.first_name} {secondaryUser.last_name}</span>
          <Link className="actionLink" to={`/users/${secondaryUser.user_id}`}>View profile</Link>
        </div>
      </div>

      <div className="flex-grow-1 overflow-hidden">
        <Card className="border-0 p-0 h-100">
          <Card.Header className={`userOpen`}>
            Exercise feedback
            {/* <span>05.05.2021</span> */}
          </Card.Header>

          <UserFeedback data={messages} primaryUser={primaryUser} secondaryUser={secondaryUser} onMessageSend={sendMessage} />
        </Card>
      </div>

    </DefaultLightBox>
  );
};

export default UsersMessage;
