import React from 'react';
import MoveIcon from '../../../../assets/icons/MoveIcon';
import GymImg from '../../../../assets/images/video.png';
import './exerciseComponents.scss';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
const ExerciseComponents = ({ data }) => {
  return (
    <>
      {data &&
        data.map((data, index) => (
          <div className="exerciseComponents" key={index}>
            <div className="d-flex align-item-center">
              <MoveIcon />
              <p className="m-0">{data.title}</p>
            </div>
            <div>
              <img src={GymImg} />
              <DropdownButton title="" drop={`start`} className={`menuDots`}>
                <Dropdown.Item href="#">Action</Dropdown.Item>
              </DropdownButton>
            </div>
          </div>
        ))}
    </>
  );
};
export default ExerciseComponents;
