import * as React from 'react';

const ArrowIcon = ({ color = '#AA957F', props }) => {
  return (
    <svg width={7} height={11} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M.981 1.752l.225.11-.225-.11a.922.922 0 00.169 1.046l2.118 2.18a.75.75 0 010 1.045L1.15 8.202a.922.922 0 001.322 1.285l3.03-3.116a1.25 1.25 0 000-1.742l-3.03-3.116a.922.922 0 00-1.49.24z"
        fill={color}
        stroke={color}
        strokeWidth={0.5}
      />
    </svg>
  );
};

export default ArrowIcon;
