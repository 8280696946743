import Dashboard from './pages/Dashboard/Dashboard';
import Login from './pages/Login/Index';
import Plans from './pages/Plans/Plans';
import MovementStyle from './pages/MovementStyle';
import Exercises from './pages/Exercises/Index';
import Users from './pages/Users/Users';
import Quizz from './pages/Quizz/Quizz';
import Messages from './pages/Messages';
import CommunityChat from './pages/CommunityChat';

const routes = [
  {
    exact: true,
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    exact: true,
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    exact: true,
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    isPrivate: true
  },
  {
    exact: true,
    path: '/plans',
    name: 'Plans',
    component: Plans,
    isPrivate: true
  },
  {
    exact: true,
    path: '/movement-style',
    name: 'Movement Style',
    component: MovementStyle,
    isPrivate: true
  },
  {
    exact: true,
    path: '/users',
    name: 'Users',
    component: Users,
    isPrivate: true
  },
  {
    exact: true,
    path: '/users/:id',
    name: 'Users',
    component: Users,
    isPrivate: true
  },
  {
    exact: true,
    path: '/exercises',
    name: 'Exercises',
    component: Exercises,
    isPrivate: true
  },
  {
    exact: true,
    path: '/messages',
    name: 'Messages',
    component: Messages,
    isPrivate: true
  },
  {
    exact: true,
    path: '/messages/:id',
    name: 'Messages',
    component: Messages,
    isPrivate: true
  },
  {
    exact: true,
    path: '/quizz',
    name: 'Quizz',
    component: Quizz,
    isPrivate: true
  },
  {
    exact: true,
    path: '/communityChat',
    name: 'CommunityChat',
    component: CommunityChat,
    isPrivate: true
  },
  {
    exact: true,
    path: '*',
    name: 'NotFoundPage',
    component: Dashboard
  }
];

export default routes;
