import * as React from 'react';

function DateIcon(props) {
  return (
    <svg width={13} height={13} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0 12a.5.5 0 00.5.5H12a.5.5 0 00.5-.5V5.437H0V12zM12 1.125H9.375v-1A.125.125 0 009.25 0h-.875a.125.125 0 00-.125.125v1h-4v-1A.125.125 0 004.125 0H3.25a.125.125 0 00-.125.125v1H.5a.5.5 0 00-.5.5v2.75h12.5v-2.75a.5.5 0 00-.5-.5z"
        fill="#AA957F"
      />
    </svg>
  );
}

export default DateIcon;
