import * as React from 'react';

function MessageIcon(props) {
  return (
    <svg width={18} height={17} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.119 17L9 16.346l2.571-4.577h3.858c.34 0 .668-.137.909-.383a1.32 1.32 0 00.376-.925V2.615c0-.346-.135-.68-.376-.924a1.275 1.275 0 00-.91-.383H2.572c-.34 0-.668.137-.909.383a1.32 1.32 0 00-.376.924v7.846c0 .347.135.68.376.925.241.246.568.383.91.383h5.785v1.308H2.571a2.55 2.55 0 01-1.818-.766A2.638 2.638 0 010 10.46V2.615C0 1.922.27 1.257.753.766A2.55 2.55 0 012.571 0H15.43a2.55 2.55 0 011.818.766c.482.49.753 1.156.753 1.85v7.845c0 .694-.27 1.36-.753 1.85a2.55 2.55 0 01-1.818.766h-3.112L10.12 17z"
        fill="#EBE9DD"
      />
      <path d="M3.857 3.923h10.286v1.308H3.857V3.923zM3.857 7.846h6.429v1.308H3.857V7.846z" fill="#EBE9DD" />
    </svg>
  );
}

export default MessageIcon;
