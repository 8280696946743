import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { get_user_profile } from '../redux/actions/AuthAction';
const DefaultAPI = () => {
  const token = Cookies.get('token');
  const dispatch = useDispatch();
  useEffect(() => {
    if (token) {
      dispatch(get_user_profile());
    }
  }, [token]);
  return <></>;
};
export default DefaultAPI;
