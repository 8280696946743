import React from 'react';

const MessagesCarousel = (props) => {
  const { description, img, name, tagline } = props.item;

  return (
  <div className="main-message">
    <div className="profile-message">
      <img src={img} alt="picture" />
    </div>
    <div className="user-message">
      <p className="name-messages">{name}</p>
      <p className="message">{description}</p>
      <p className="exercise-message pt-2">{tagline}</p>
    </div>
  </div>
  );
};

export default MessagesCarousel;
