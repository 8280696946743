import React, { useState } from 'react';

import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import InputTitle from '../../UI/InputTitle';

const AddExercise = ({ category, onSubmit }) => {
  const [name, setName] = useState('');
  const [file, setFile] = useState(null);

  const onSubmitHandle = event => {
    event.preventDefault();
    onSubmit(category, name, file);
  }

  const onNameChangeHandle = event => {
    setName(event.target.value);
  }

  const onFileChangeHandle = event => {
    setFile(event.target.files[0]);
  }

  return (
    <>
      <Card className={`border-0 VideoLevelTwo`}>
        <Card.Header className={`p-0`}>
          <Form className="w-100" onSubmit={onSubmitHandle}>
            <Row className={`w-100 ps-3`}>
              <Col md={5} className={`align-item-center d-flex my-1`}>
                <InputTitle className={`me-2`}>NAME</InputTitle>
                <Form.Control type="text" value={name} onChange={onNameChangeHandle} placeholder="Exercise name..." required />
              </Col>
              <Col md={5} className={`align-item-center d-flex my-1`}>
                <InputTitle className={`me-2`}>Video</InputTitle>
                <Form.Control type="file" onChange={onFileChangeHandle} required />
              </Col>
              <Col md={2} className={`align-item-center d-flex my-1`}>
                <button className="actionMoment" type="submit">
                  <span className="secondaryText">Add exercise</span>
                </button>
              </Col>
            </Row>
          </Form>
        </Card.Header>
      </Card>
    </>
  );
};

export default AddExercise;
