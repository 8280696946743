import React from 'react';
import DummyImg from '../../../../../assets/images/video.png';
import './savedImages.scss';
const SavedImages = () => {
  const data = ['Overhead squat', 'Shoulder Mobility', 'Pushup', 'Reverse Lunge ', 'Straight Leg Raise'];
  return (
    <div className="saveImagesTable w-100">
      <table className="w-100">
        <tr className="saveImagesTitle">
          <td>exercise name</td>
          <td>Starting video</td>
          <td>most recent</td>
        </tr>
        {data.map((data, index) => (
          <tr key={index}>
            <td>{data}</td>
            <td>
              <img src={DummyImg} />
            </td>
            <td>
              <img src={DummyImg} />
            </td>
          </tr>
        ))}
      </table>
    </div>
  );
};
export default SavedImages;
