import React, { useState } from 'react';
import loginCss from './loginCss';
import SignIn from '../../components/SignIn/Index';
import SignUp from '../../components/SignUp/Index';
import logo from '../../assets/images/bm_logo_bone1.png';

const Login = () => {
  const classes = loginCss();
  const [loginScreen, setLoginScreen] = useState(true);

  return (
    <div className={classes.main}>
      <img src={logo} alt="Logo" className={`pb-4`} />
      <div className={classes.authBox}>
        <div className={`row loginTitle`}>
          <div className={`col-6 pb-4`}>
            <h4
              className={`${classes.loginTitel} ${loginScreen && classes.activeModelTitle}`}
              onClick={() => setLoginScreen(true)}
            >
              Login
            </h4>
          </div>
          {/*<div className={`col-6 pb-4 text-end`}>*/}
          {/*    <h4*/}
          {/*        className={`${classes.loginTitel} ${!loginScreen &&*/}
          {/*            'activeModelTitle'}`}*/}
          {/*        onClick={() => setLoginScreen(false)}*/}
          {/*    >*/}
          {/*        Sign up*/}
          {/*    </h4>*/}
          {/*</div>*/}
        </div>

        {loginScreen ? <SignIn /> : <SignUp />}
      </div>
    </div>
  );
};
export default Login;
