import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from '@material-ui/core/Button';
import QuizAns from './QuizAns';
import InputTitle from '../UI/InputTitle';

const QuizQuestionLevelTwo = ({
  questionData = {},
  addNewQuestion,
  setQuestionText,
  setAnswerText,
  handleAddAnswer,
  answers = [],
}) => {
  const { text, options } = questionData;
  const handleChangeQuestion = ({ target: { value } }) => {
    setQuestionText(value);
  };

  return (
    <>
      <div className={`newVideoWrapper pt-3 borderBottom`}>
        <div className={`d-flex align-item-center`}>
          <InputTitle className={`pt-2 me-3`}>Question</InputTitle>
        </div>
        <div className={`w-100`}>
          <Form.Group className="mb-3">
            <Form.Control
              type="text"
              placeholder="Question"
              name="questionText"
              value={text}
              onChange={handleChangeQuestion}
              disabled={!addNewQuestion}
            />
          </Form.Group>
        </div>
      </div>
      <div className={`ans`}>
        {answers.map((answer, index) => (
          <QuizAns setAnswerText={(value) => setAnswerText(value, index)} answerText={answer.text} key={index} />
        ))}
      </div>

      <div className={`p-3`}>
        <Button className={`whiteBtn px-4`} onClick={handleAddAnswer}>
          + Add Answer
        </Button>
      </div>
    </>
  );
};
export default QuizQuestionLevelTwo;
