import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import Button from '../../../Atoms/Button/index';
import Breadcrumb from '../../Breadcrumb/Breadcrumb';
import Loader from '../../Loader/Loader';

import UserProfileLeftContent from './UserProfileLeftContent';
import UserProfileRightContent from './UserProfileRightContent';

import {
  getUser,
  getUserData,
  getUserPercentage,
  updateUser
} from '../../../redux/actions/UsersAction';

import './style.scss';

const UserProfile = ({ id }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const navigation = ['Users'];

  useEffect(() => {
    dispatch(getUser(id));
  }, []);

  const {
    getUser: { data, loading },
    getUserData: { data: userData },
    getUserPercentage: { data: userPercentage }
  } = useSelector(({ users }) => users);

  useEffect(() => {
    if (data) {
      dispatch(getUserData(data.id));
      dispatch(getUserPercentage(data.id));
    }
  }, [data]);

  const onUserUpdateHandle = data => {
    dispatch(updateUser({ id, data }));
  }

  const onSendMessageClickHandle = () => {
    history.push(`/messages/${id}`);
  }

  return (
    <>
      <Breadcrumb navigation={navigation}>
        <div className="breadcrumbButtons">
          <Button text="Send message" onClick={onSendMessageClickHandle} />
        </div>
      </Breadcrumb>

      <div className="relative">
        {data && <Row>
          <Col lg={7} className="pb-3">
            <UserProfileLeftContent />
          </Col>

          <Col lg={5} className="pb-3">
            <UserProfileRightContent user={data} userData={userData} userPercentage={userPercentage} onUpdate={onUserUpdateHandle} />
          </Col>
        </Row>}

        {loading && <Loader className={data ? 'flexibleLoader' : 'fullPageLoader'} />}
      </div>
    </>
  );
};

export default UserProfile;
