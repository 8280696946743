import React, { useState } from 'react';
import PlansTable from '../PlansTable/PlansTable';
import NewUsersTable from '../NewUsersTable/NewUsersTable';
import './main.css';

export default function Main() {
  return (
    <div className="row pb-4">
      <div className={`col-lg-8`}>
        <NewUsersTable />
      </div>
      <div className={`col-lg-4`}>
        <PlansTable />
      </div>
    </div>
  );
}
