import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import CustomToggleExercises from './CustomToggleExercises';
import { Accordion } from 'react-bootstrap';
import ConnectvideoLevelTwo from './ConnectvideoLevelTwo';

const ConnectvideoLevelOne = () => {
  const [activeKey, setActiveKey] = useState('1');

  return (
    <>
      <Accordion defaultActiveKey="0" activeKey={activeKey}>
        <Card className={`border-0`}>
          <Card.Header className={`p-0 videoLevelOne`}>
            <CustomToggleExercises setActiveKey={setActiveKey} activeKey={activeKey} eventKey={`0`}>
              &nbsp; Add single video
            </CustomToggleExercises>
          </Card.Header>

          <Accordion.Collapse eventKey="0">
            <>
              <ConnectvideoLevelTwo />
            </>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </>
  );
};

export default ConnectvideoLevelOne;
