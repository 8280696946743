import React, { useEffect, useState } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import MovementTypesList from '../../components/MovementStyle/MovementTypesList';
import Loader from '../../components/Loader/Loader';

import { useDispatch, useSelector } from 'react-redux';
import { getUserCorelation, getUserType } from '../../redux/actions/PlansAction';
import { getQuestion } from '../../redux/actions/QuizzAction';
import { getMovementTypes } from '../../redux/actions/MovementTypesAction';

const MovementStyle = () => {
  const navigation = ['Movement Style Management'];

  const dispatch = useDispatch();

  const [activeScreen, setActiveScreen] = useState('0');
  const [loading, setLoading] = useState(true);

  const {
    getUser: { success: getUserSuccess },
    getUserCorelation: { loading: getCorelationLoading }
  } = useSelector(({ plan }) => plan);

  const {
    getMovementTypes: types,
  } = useSelector(({ movementTypes }) => movementTypes);

  useEffect(async () => {
    dispatch(getMovementTypes());
    dispatch(getUserType());
    dispatch(getQuestion());
    dispatch(getUserCorelation());
  }, []);

  useEffect(() => {
    if (getUserSuccess && !types.loading)
      setLoading(false);

    if (!types.success && types.data.results) {
      const result = types.data.results.find(result => result.errors);
      result && setActiveScreen(result.id);
    }
  }, [getUserSuccess, types]);

  return (
    <div>
      {loading ? (
        <div>
          <Loader className="fullPageLoader" />
        </div>
      ) : (
        <>
          <Breadcrumb navigation={navigation} className={'mb-0'} />

          <Row>
            <Col lg={7} className="pt-3 relative">
              <MovementTypesList
                activeScreen={activeScreen}
                setActiveScreen={setActiveScreen}
                typesData={types.data.results}
                loading={types.loading}
              />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}

export default MovementStyle;
