import { all, call, put, takeLatest } from 'redux-saga/effects';

import { Axios } from '../../api/axios';
import { getSimplifiedError } from '../../utils/error';
import { toast } from 'react-toastify';

import {
  RESET_FLAGS_QUIZZ,
  CREATE_QUESTION_REQUEST,
  CREATE_QUESTION_SUCCESS,
  CREATE_QUESTION_ERROR,
  CREATE_ANSWER_REQUEST,
  CREATE_ANSWER_SUCCESS,
  CREATE_ANSWER_ERROR,
  GET_QUESTION_REQUEST,
  GET_QUESTION_SUCCESS,
  GET_QUESTION_ERROR,
  EDIT_QUESTION_REQUEST,
  EDIT_QUESTION_SUCCESS,
  EDIT_QUESTION_ERROR,
  DELETE_QUESTION_REQUEST,
  DELETE_QUESTION_SUCCESS,
  DELETE_QUESTION_ERROR,
  EDIT_OPTION_REQUEST,
  EDIT_OPTION_SUCCESS,
  EDIT_OPTION_ERROR,
  DELETE_OPTION_REQUEST,
  DELETE_OPTION_SUCCESS,
  DELETE_OPTION_ERROR,
} from '../reducers/QuizzReducer';

async function getQuestion(payload) {
  return await Axios.get('/questionnaire/', payload);
}
function* handleGetQuestion({ payload }) {
  try {
    const response = yield call(getQuestion, payload);
    if (response) {
      yield put({
        type: GET_QUESTION_SUCCESS,
        data: response.results,
      });
      yield put({
        type: RESET_FLAGS_QUIZZ,
        payload: { blockType: 'getQuestion' },
      });
    }
  } catch (error) {
    yield put({
      type: GET_QUESTION_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

async function createQuestion(payload) {
  return await Axios.post('/questionnaire/', payload);
}
function* handleCreateQuestion({ payload }) {
  try {
    const response = yield call(createQuestion, payload);
    if (response) {
      yield put({
        type: CREATE_QUESTION_SUCCESS,
        data: response,
      });
      yield put({
        type: RESET_FLAGS_QUIZZ,
        payload: { blockType: 'createQuestion' },
      });
    }
  } catch (error) {
    yield put({
      type: CREATE_QUESTION_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

async function createAnswer({ questionId, payload }) {
  return await Axios.post(`/questionnaire/${questionId}/add_option/`, payload);
}
function* handleCreateAnswer({ payload }) {
  try {
    const response = yield call(createAnswer, payload);
    if (response) {
      yield put({
        type: CREATE_ANSWER_SUCCESS,
        data: response,
      });
      yield put({
        type: RESET_FLAGS_QUIZZ,
        payload: { blockType: 'createAnswer' },
      });
    }
  } catch (error) {
    yield put({
      type: CREATE_ANSWER_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

async function editQuestion({ id, data }) {
  return await Axios.patch(`/questionnaire/${id}/`, data);
}
function* handleEditQuestion({ payload }) {
  try {
    const response = yield call(editQuestion, payload);
    if (response) {
      yield put({
        type: EDIT_QUESTION_SUCCESS,
      });
      yield put({
        type: RESET_FLAGS_QUIZZ,
        payload: { blockType: 'editQuestion' },
      });
    }
  } catch (error) {
    yield put({
      type: EDIT_QUESTION_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

async function deleteQuestion({ id }) {
  return await Axios.delete(`/questionnaire/${id}/`);
}
function* handleDeleteQuestion({ payload }) {
  try {
    const data = payload;
    const response = yield call(deleteQuestion, payload);
    if (response === '') {
      yield put({
        type: DELETE_QUESTION_SUCCESS,
        data,
      });
      yield put({
        type: RESET_FLAGS_QUIZZ,
        payload: { blockType: 'deleteQuestion' },
      });
    }
  } catch (error) {
    yield put({
      type: DELETE_QUESTION_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

async function editOption({ data }) {
  return await Axios.patch(`/questionnaire/update_option/`, data);
}
function* handleEditOption({ payload }) {
  try {
    const response = yield call(editOption, payload);
    if (response) {
      yield put({
        type: EDIT_OPTION_SUCCESS,
      });
      yield put({
        type: RESET_FLAGS_QUIZZ,
        payload: { blockType: 'editOption' },
      });
    }
  } catch (error) {
    yield put({
      type: EDIT_OPTION_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

async function deleteOption({ id }) {
  return await Axios.delete(`/questionnaire/${id}/delete_option/`);
}
function* handleDeleteOption({ payload }) {
  try {
    const data = payload;
    const response = yield call(deleteOption, payload);
    if (response === '') {
      yield put({
        type: DELETE_OPTION_SUCCESS,
        data,
      });
      yield put({
        type: RESET_FLAGS_QUIZZ,
        payload: { blockType: 'deleteOption' },
      });
    }
  } catch (error) {
    yield put({
      type: DELETE_OPTION_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

export default all([
  takeLatest(GET_QUESTION_REQUEST, handleGetQuestion),
  takeLatest(CREATE_QUESTION_REQUEST, handleCreateQuestion),
  takeLatest(CREATE_ANSWER_REQUEST, handleCreateAnswer),
  takeLatest(EDIT_QUESTION_REQUEST, handleEditQuestion),
  takeLatest(DELETE_QUESTION_REQUEST, handleDeleteQuestion),
  takeLatest(EDIT_OPTION_REQUEST, handleEditOption),
  takeLatest(DELETE_OPTION_REQUEST, handleDeleteOption),
]);
