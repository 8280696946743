import React from 'react';
import { useParams, useLocation } from 'react-router-dom';

import Loader from '../../components/Loader/Loader';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import MessagesComponents from '../../components/Messages/Messages';

const Messages = () => {
  const { id } = useParams();

  const navigation = ['Message center'];
  const loading = false;

  return (
    <div>
      {loading
      ? <Loader className="fullPageLoader" />
      : (
        <>
          <Breadcrumb
            navigation={navigation}
            className={'mb-0'}
          />
          <MessagesComponents selectedUserId={id} />
        </>
      )}
    </div>
  );
};

export default Messages;
