import { all, call, put, takeLatest } from 'redux-saga/effects';
import Cookies from 'js-cookie';

import { Axios } from '../../api/axios';
import { getSimplifiedError } from '../../utils/error';
import {
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
  LOGIN_ERROR,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  GET_USER_PROFILE_REQUEST,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_ERROR,
  RESET_FLAGS_AUTH,
} from '../reducers/AuthReducer';
import { toast } from 'react-toastify';

async function signup(payload) {
  return await Axios.post('/signup/', payload);
}
function* handleSignup({ payload }) {
  try {
    const response = yield call(signup, payload);
    if (response) {
      yield put({
        type: SIGNUP_SUCCESS,
      });
    }
  } catch (error) {
    yield put({
      type: SIGNUP_ERROR,
      error: getSimplifiedError(error),
    });
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

async function login(payload) {
  return await Axios.post('/login/', payload);
}
function* handleLogin({ payload }) {
  try {
    const response = yield call(login, payload);
    if (response.token) {
      const {
        token,
        user: { is_superuser },
      } = response;
      if (!is_superuser) {
        return yield put({
          type: LOGIN_ERROR,
          error: 'You are not authorised to use this platform',
        });
      }
      const options = { path: '/' };
      Cookies.set('token', token, options);
      yield put({
        type: LOGIN_SUCCESS,
      });
    }
  } catch (error) {
    yield put({
      type: LOGIN_ERROR,
      error: getSimplifiedError(error),
    });
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

async function get_user() {
  return await Axios.get('/user/get_user_profile/');
}
function* get_user_profile() {
  try {
    const response = yield call(get_user);
    if (response) {
      yield put({
        type: GET_USER_PROFILE_SUCCESS,
        data: response,
      });
      yield put({
        type: RESET_FLAGS_AUTH,
        payload: { blockType: 'loginUser' },
      });
    }
  } catch (error) {
    yield put({
      type: GET_USER_PROFILE_ERROR,
      error: getSimplifiedError(error),
    });
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

export default all([
  takeLatest(SIGNUP_REQUEST, handleSignup),
  takeLatest(LOGIN_REQUEST, handleLogin),
  takeLatest(GET_USER_PROFILE_REQUEST, get_user_profile),
]);
