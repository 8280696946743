import {
  RESET_BLOCK_QUIZZ,
  RESET_FLAGS_QUIZZ,
  GET_QUESTION_REQUEST,
  CREATE_QUESTION_REQUEST,
  CREATE_ANSWER_REQUEST,
  EDIT_QUESTION_REQUEST,
  DELETE_QUESTION_REQUEST,
  EDIT_OPTION_REQUEST,
  DELETE_OPTION_REQUEST,
} from '../reducers/QuizzReducer';

export const getQuestion = (payload) => ({ type: GET_QUESTION_REQUEST, payload });

export const createQuestion = (payload) => ({
  type: CREATE_QUESTION_REQUEST,
  payload,
});
export const createAnswer = (payload) => ({
  type: CREATE_ANSWER_REQUEST,
  payload,
});

export const editQuestion = (payload) => ({
  type: EDIT_QUESTION_REQUEST,
  payload,
});

export const deleteQuestion = (payload) => ({
  type: DELETE_QUESTION_REQUEST,
  payload,
});

export const editOption = (payload) => ({
  type: EDIT_OPTION_REQUEST,
  payload,
});

export const deleteOption = (payload) => ({
  type: DELETE_OPTION_REQUEST,
  payload,
});

export const resetBlockQuizz = (payload) => ({
  type: RESET_BLOCK_QUIZZ,
  payload,
});

export const resetFlagQuizz = (payload) => ({
  type: RESET_FLAGS_QUIZZ,
  payload,
});
