import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DefaultLightBox from '../../UI/DefaultLightBox';
import filter from '../../../assets/icons/filter-green.svg';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Form from 'react-bootstrap/Form';
import './userMessageList.scss';
import search from '../../../assets/icons/search.svg';
import UserChatList from './UserChatList';

const theme = createTheme({
  palette: {
    primary: {
      main: '#62A8A4',
      contrastText: '#fff',
    },
  },
});

const UserMessageList = ({ data, selectedUserId }) => {
  const classes = useStyles();
  const totalUnreadCount = data.reduce((c, chat) => c + chat.unReadMessagesCount, 0);

  return (
    <ThemeProvider theme={theme}>
      <DefaultLightBox className="userMessageListWrapper">
        <Row className={`p-3 relative align-item-center pb-2`}>
          <Col>
            <span>Messages {totalUnreadCount ? `(${totalUnreadCount})` : ''}</span>
          </Col>
          <Col className="text-end">
            <Button variant="outlined" className={classes.button}>
              <img src={filter} alt="filter" />
              <p className="searchBar-content">Filters</p>
              <Badge badgeContent={'0'} className={classes.spacing} color="primary" />
            </Button>
          </Col>
        </Row>
        <Row className="searchInputWrapper">
          <Col>
            <Form.Group className="p-2 inputBoxWrapper">
              <Form.Control type="text" placeholder="Search messages" name="search" />
              <Button className="p-0 iconAction">
                <img src={search} alt="search" />
              </Button>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="userListWrapper">
              {data.map(chatData => <UserChatList data={chatData} key={chatData.id} className={selectedUserId == chatData.secondaryUser.userID ? 'activeList' : ''} />)}
            </div>
          </Col>
        </Row>
        {/* <Row>
          <Col>
            <p className="loadMoreBtn text-center p-3">Load more</p>
          </Col>
        </Row> */}
      </DefaultLightBox>
    </ThemeProvider>
  );
};

const useStyles = makeStyles(theme => ({
  button: {
    color: '#62A8A4',
    textTransform: 'capitalize',
    width: '120px',
    height: '35px',
  },
  spacing: {
    margin: '0 10px',
  },
  MuiBadge: {
    backgroundColor: 'red',
  },
}));

export default UserMessageList;
