import { all, call, put, takeLatest } from 'redux-saga/effects';

import { Axios } from '../../api/axios';
import { getSimplifiedError } from '../../utils/error';
import { toast } from 'react-toastify';

import {
  GET_PLANS_STATISTIC_REQUEST,
  GET_PLANS_STATISTIC_SUCCESS,
  GET_PLANS_STATISTIC_ERROR,

  GET_TOTAL_DATA_REQUEST,
  GET_TOTAL_DATA_SUCCESS,
  GET_TOTAL_DATA_ERROR
} from '../reducers/StatisticsReducer';

async function getPlansStatistic(payload) {
  return await Axios.get('/user/user_plans/', payload);
}

function* handleGetPlansStatistic({ payload }) {
  try {
    const response = yield call(getPlansStatistic, payload);

    if (response) {
      yield put({
        type: GET_PLANS_STATISTIC_SUCCESS,
        data: response,
      });
    }
  } catch (error) {
    yield put({
      type: GET_PLANS_STATISTIC_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

async function getTotalData(payload) {
  return await Axios.get('/user/get_total_data/', payload);
}

function* handleGetTotalData({ payload }) {
  try {
    const response = yield call(getTotalData, payload);

    if (response) {
      yield put({
        type: GET_TOTAL_DATA_SUCCESS,
        data: response,
      });
    }
  } catch (error) {
    yield put({
      type: GET_TOTAL_DATA_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

export default all([
  takeLatest(GET_PLANS_STATISTIC_REQUEST, handleGetPlansStatistic),
  takeLatest(GET_TOTAL_DATA_REQUEST, handleGetTotalData)
]);
