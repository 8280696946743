import React, { useState } from 'react';
import DaysWeekBar from '../../DaysWeekBar/DaysWeekBar';
import { Accordion } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import MoveIcon from '../../../../assets/icons/MoveIcon';
import CustomToggleExercises from '../../../Exercises/ExercisesAccordionUi/CustomToggleExercises';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import './nutritionPlans.scss';
import NutritionPlansCollapse from './NutritionPlansCollapse';
import Button from '@material-ui/core/Button';
const NutritionPlansLevel = () => {
  const [activeKey, setActiveKey] = useState('1');
  const data = [
    { id: 1, title: 'Sleep at least 8 hours today' },
    { id: 2, title: 'Meditate for 5 minutes daily' },
    { id: 3, title: 'Got for a walk at least two times a week' },
  ];
  return (
    <div className="nutritionPlansWrapper">
      <DaysWeekBar days={['MON', 'WED', 'SAT']} week={[1, 2]} month={[1, 2]} />
      <div className="nutritionAccordion">
        <Accordion defaultActiveKey="0" activeKey={activeKey}>
          {data.map((data, index) => (
            <Card className={`border-0`} key={index}>
              <Card.Header className={`accordionNutrition`}>
                <MoveIcon />
                <CustomToggleExercises setActiveKey={setActiveKey} eventKey={index} activeKey={activeKey}>
                  &nbsp; {data.title}
                </CustomToggleExercises>
                <DropdownButton title="" drop={`start`} className={`menuDots`}>
                  <Dropdown.Item href="#">Action</Dropdown.Item>
                </DropdownButton>
              </Card.Header>

              <Accordion.Collapse eventKey={index}>
                <>
                  <NutritionPlansCollapse />
                </>
              </Accordion.Collapse>
            </Card>
          ))}
        </Accordion>
      </div>
      <div className="actionFooter">
        <Button className="whiteBtn">+ Add time selection</Button>
        <Button className="whiteBtn">+ Add habit</Button>
      </div>
    </div>
  );
};
export default NutritionPlansLevel;
