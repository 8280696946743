import React from 'react';

import UserCards from './UserCards/UserCards';

import './newUsers.css';

export default function newUsers({ users }) {
  return (
    <div className="users-container">
      <p className="users-title">New Users</p>

      <div className="cards">
        {users.map(user => {
          return <UserCards key={user.id} data={user} />;
        })}
      </div>
    </div>
  );
}
