import React from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import NutritionPlan from './NutritionPlans/NutritionPlan';
import PlansUsers from './PlansUsers/PlansUsers'
import Form from 'react-bootstrap/Form';
import { useSelector } from 'react-redux';
import Loader from '../../../Loader/Loader';

const Nutrition = ({ userTypeId }) => {
  const {
    nutritionLevel: { loading },
  } = useSelector(({ plan }) => plan);

  return (
    <>
      {loading ? (
        <div className="text-center borderBottom">
          <Loader className="smallLoader" />
        </div>
      ) : (
        <div className="addActionUi">
          <Tabs defaultActiveKey="Nutrition" id="NutritionPlans" className="titleTab">
            <Tab eventKey="Nutrition" title="Nutrition plans">
              <NutritionPlan userTypeId={userTypeId} />
            </Tab>
            <Tab eventKey="users" title="users">
              <PlansUsers userTypeId={userTypeId} />
            </Tab>
            <Tab eventKey="plan" title="Plan description">
              <div className="descriptionBox">
                <p>My black movement description for this plan</p>
                <Form.Control as="textarea" style={{ height: '100px' }} />
              </div>
            </Tab>
          </Tabs>
        </div>
      )}
    </>
  );
};

export default Nutrition;
