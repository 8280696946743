import React from 'react';
import { AreaChart, Area, XAxis, ResponsiveContainer } from 'recharts';

const AreaLineChart = ({ title, data }) => {
  const dataProcessed = data.map(record => ({ date: record.date.substring(5, record.date.length), value: parseInt(record.value) }));

  return (
    <div className="w-100 relative borderBottom">
      <span className="chartName">{title} </span>
      <ResponsiveContainer width="100%" height={75}>
        <AreaChart
          height={75}
          data={dataProcessed}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <XAxis dataKey="date" />
          {/*<Tooltip />*/}
          <Area connectNulls type="monotone" dataKey="value" stroke="#F4F3EE" fill="#EBE9E0" />
        </AreaChart>
      </ResponsiveContainer>
      <div className="bgColor" />
    </div>
  );
};

export default AreaLineChart;
