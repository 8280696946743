import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ConnectvideoLevelOne from './ExercisesAccordionUi/ConnectvideoLevelOne';
import DefaultLightBox from '../UI/DefaultLightBox';
const ConnectNewVideo = () => {
  return (
    <>
      <DefaultLightBox>
        <Row className={`p-3`}>
          <Col>
            <p>Video Library</p>
          </Col>
        </Row>

        <div className={`addVideo`}>
          <ConnectvideoLevelOne />
        </div>
      </DefaultLightBox>
    </>
  );
};
export default ConnectNewVideo;
