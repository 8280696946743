import React from 'react';
import moment from 'moment';
import Highlighter from "react-highlight-words";

import placeholder from '../../../assets/images/placeholder3.png';

const SendWrapper = ({ message, searchQuery }) => {
  return (
    <div className="sendWrapper">
      <div className="messageWrapper">
        <div className="message">
          <Highlighter
            highlightClassName="search-result"
            searchWords={[searchQuery]}
            autoEscape={true}
            textToHighlight={message.text}
          />
        </div>
        <div className="date">
          <span>{message.user.name} {message.createdAt && moment(message.createdAt.seconds * 1000).format('HH:mm - MMM DD')}</span>
        </div>
      </div>
      <div className="userProfile">
        <img src={placeholder} width={50} height={50} />
      </div>
    </div>
  );
};

export default SendWrapper;
