import { orderBy } from 'lodash';

export const RESET_BLOCK_QUIZZ = 'RESET_BLOCK_QUIZZ';
export const RESET_FLAGS_QUIZZ = 'RESET_FLAGS_QUIZZ';

export const GET_QUESTION_REQUEST = 'GET_QUESTION_REQUEST';
export const GET_QUESTION_SUCCESS = 'GET_QUESTION_SUCCESS';
export const GET_QUESTION_ERROR = 'GET_QUESTION_ERROR';

export const CREATE_QUESTION_REQUEST = 'CREATE_QUESTION_REQUEST';
export const CREATE_QUESTION_SUCCESS = 'CREATE_QUESTION_SUCCESS';
export const CREATE_QUESTION_ERROR = 'CREATE_QUESTION_ERROR';

export const CREATE_ANSWER_REQUEST = 'CREATE_ANSWER_REQUEST';
export const CREATE_ANSWER_SUCCESS = 'CREATE_ANSWER_SUCCESS';
export const CREATE_ANSWER_ERROR = 'CREATE_ANSWER_ERROR';

export const EDIT_QUESTION_REQUEST = 'EDIT_QUESTION_REQUEST';
export const EDIT_QUESTION_SUCCESS = 'EDIT_QUESTION_SUCCESS';
export const EDIT_QUESTION_ERROR = 'EDIT_QUESTION_ERROR';

export const DELETE_QUESTION_REQUEST = 'DELETE_QUESTION_REQUEST';
export const DELETE_QUESTION_SUCCESS = 'DELETE_QUESTION_SUCCESS';
export const DELETE_QUESTION_ERROR = 'DELETE_QUESTION_ERROR';

export const EDIT_OPTION_REQUEST = 'EDIT_OPTION_REQUEST';
export const EDIT_OPTION_SUCCESS = 'EDIT_OPTION_SUCCESS';
export const EDIT_OPTION_ERROR = 'EDIT_OPTION_ERROR';

export const DELETE_OPTION_REQUEST = 'DELETE_OPTION_REQUEST';
export const DELETE_OPTION_SUCCESS = 'DELETE_OPTION_SUCCESS';
export const DELETE_OPTION_ERROR = 'DELETE_OPTION_ERROR';

const block = {
  loading: false,
  error: '',
  success: false,
};

const initialState = {
  getQuestion: { ...block, data: [] },
  createQuestion: { ...block },
  createAnswer: { ...block },
  editQuestion: { ...block },
  deleteQuestion: { ...block },
  editOption: { ...block },
  deleteOption: { ...block },
};

export const QuizzReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_QUESTION_REQUEST:
      return {
        ...state,
        getQuestion: { ...state.getQuestion, loading: true },
      };
    case GET_QUESTION_SUCCESS:
      const updatedQuestionData = action.data.map((question) => {
        return {
          ...question,
          options: orderBy(question.options, 'order', 'asc'),
        };
      });
      return {
        ...state,
        getQuestion: {
          ...state.getQuestion,
          loading: false,
          success: true,
          data: updatedQuestionData,
        },
      };
    case GET_QUESTION_ERROR:
      return {
        ...state,
        getQuestion: {
          ...state.getQuestion,
          loading: false,
          error: action.error,
        },
      };

    case CREATE_QUESTION_REQUEST:
      return {
        ...state,
        createQuestion: { ...state.createQuestion, loading: true },
      };
    case CREATE_QUESTION_SUCCESS:
      return {
        ...state,
        createQuestion: {
          ...state.createQuestion,
          loading: false,
          success: true,
          error: '',
        },
        getQuestion: {
          ...state.getQuestion,
          data: [...state.getQuestion.data, action.data],
        },
      };
    case CREATE_QUESTION_ERROR:
      return {
        ...state,
        createQuestion: {
          ...state.createQuestion,
          loading: false,
          error: action.error,
        },
      };

    case CREATE_ANSWER_REQUEST:
      return {
        ...state,
        createAnswer: { ...state.createAnswer, loading: true },
      };
    case CREATE_ANSWER_SUCCESS:
      const updateData = state.getQuestion.data.filter((data) => data.id !== action.data.id);
      updateData.push(action.data);
      return {
        ...state,
        createAnswer: {
          ...state.createAnswer,
          loading: false,
          success: true,
          error: '',
        },
        getQuestion: {
          ...state.getQuestion,
          data: updateData,
        },
      };
    case CREATE_ANSWER_ERROR:
      return {
        ...state,
        createAnswer: {
          ...state.createAnswer,
          loading: false,
          error: action.error,
        },
      };

    case EDIT_QUESTION_REQUEST:
      return {
        ...state,
        editQuestion: { ...state.editQuestion, loading: true },
      };
    case EDIT_QUESTION_SUCCESS:
      return {
        ...state,
        editQuestion: {
          ...state.editQuestion,
          loading: false,
          success: true,
        },
      };
    case EDIT_QUESTION_ERROR:
      return {
        ...state,
        editQuestion: {
          ...state.editQuestion,
          loading: false,
          error: action.error,
        },
      };

    case DELETE_QUESTION_REQUEST:
      return {
        ...state,
        deleteQuestion: { ...state.deleteQuestion, loading: true },
      };
    case DELETE_QUESTION_SUCCESS:
      let updatedGetQuestion = state.getQuestion.data.filter((question) => {
        return question.id !== action.data.id;
      });
      return {
        ...state,
        deleteQuestion: {
          ...state.deleteQuestion,
          loading: false,
          success: true,
        },
        getQuestion: {
          ...state.getQuestion,
          data: updatedGetQuestion,
        },
      };
    case DELETE_QUESTION_ERROR:
      return {
        ...state,
        deleteQuestion: {
          ...state.deleteQuestion,
          loading: false,
          error: action.error,
        },
      };

    case EDIT_OPTION_REQUEST:
      return {
        ...state,
        editOption: { ...state.editOption, loading: true },
      };
    case EDIT_OPTION_SUCCESS:
      return {
        ...state,
        editOption: {
          ...state.editOption,
          loading: false,
          success: true,
        },
      };
    case EDIT_OPTION_ERROR:
      return {
        ...state,
        editOption: {
          ...state.editOption,
          loading: false,
          error: action.error,
        },
      };

    case DELETE_OPTION_REQUEST:
      return {
        ...state,
        deleteOption: { ...state.deleteOption, loading: true },
      };
    case DELETE_OPTION_SUCCESS:
      const updatedData = state.getQuestion.data.map((item) => {
        return {
          ...item,
          options: item.options.filter((option) => option.id !== action.data.id),
        };
      });
      return {
        ...state,
        deleteOption: {
          ...state.deleteOption,
          loading: false,
          success: true,
        },
        getQuestion: {
          ...state.getQuestion,
          data: updatedData,
        },
      };

    case DELETE_OPTION_ERROR:
      return {
        ...state,
        deleteOption: {
          ...state.deleteOption,
          loading: false,
          error: action.error,
        },
      };

    //reset block with flag and data
    case RESET_BLOCK_QUIZZ:
      return {
        ...state,
        [action.payload.blockType]: {
          ...state[action.payload.blockType],
          ...initialState[action.payload.blockType],
        },
      };

    case RESET_FLAGS_QUIZZ:
      return {
        ...state,
        [action.payload.blockType]: {
          ...state[action.payload.blockType],
          ...block,
        },
      };

    default:
      return state;
  }
};
