import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import MoveIcon from '../../../assets/icons/MoveIcon';
import CustomToggleExercises from '../../Exercises/ExercisesAccordionUi/CustomToggleExercises';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Nutrition from './Nutrition/Nutrition';
import Movement from './Movement/Movement';
import MorningMoments from './MorningMoments/MorningMoments';
import Sleep from './Sleep/Sleep';
import { useDispatch } from 'react-redux';
import {
    getMorningMoment,
    getMovementExerciselibraryCategory,
    getMovementWorkout,
    getMovementWorkoutLevel,
    getNutritionHabit,
    getNutritionLevel,
    getNutritionPlan
} from '../../../redux/actions/PlansAction';
const PlansManagementWrapper = ({ setActiveScreen, userTypeId }) => {
    const dispatch = useDispatch();

    const [activeKey, setActiveKey] = useState('1');
    const data = [
        { id: 1, title: 'Movement' },
        { id: 2, title: 'Nutrition' },
        { id: 3, title: 'Morning moments' }
    ];
    const plansScreen = name => {
        switch (name) {
            case 'Movement':
                return <Movement userTypeId={userTypeId} />;
            case 'Nutrition':
                return <Nutrition userTypeId={userTypeId} />;
            case 'Morning moments':
                return <MorningMoments userTypeId={userTypeId} />;
            case 'Body Composition':
                return <Sleep />;
        }
    };

    function handlePlansScreenView(title) {
        setActiveScreen(title);
        if (title === 'Movement') {
            dispatch(getMovementWorkoutLevel());
            dispatch(getMovementWorkout());
            dispatch(getMovementExerciselibraryCategory());
        }
        if (title === 'Nutrition') {
            dispatch(getNutritionLevel());
            dispatch(getNutritionPlan());
            dispatch(getNutritionHabit());
        }
        if (title === 'Morning moments') {
            dispatch(getMorningMoment());
        }
    }

    return (
        <div className="plansManagementWrapper">
            <div className="nutritionAccordion">
                <Accordion defaultActiveKey="0" activeKey={activeKey}>
                    {data.map((data, index) => {
                        const { title = 'Title', id } = data;
                        return (
                            <div key={index}>
                                <Card className={`border-0`}>
                                    <Card.Header className={`accordionWorkout`}>
                                        <MoveIcon />
                                        <div
                                            onClick={() =>
                                                handlePlansScreenView(title)
                                            }
                                        >
                                            <CustomToggleExercises
                                                setActiveKey={setActiveKey}
                                                eventKey={index}
                                                activeKey={activeKey}
                                            >
                                                &nbsp; {title}
                                            </CustomToggleExercises>
                                        </div>
                                        {/* <DropdownButton
                                            title=""
                                            drop={`start`}
                                            className={`menuDots`}
                                        >
                                            <Dropdown.Item href="#">
                                                Action
                                            </Dropdown.Item>
                                        </DropdownButton> */}
                                    </Card.Header>

                                    <Accordion.Collapse eventKey={index}>
                                        <>{plansScreen(title)}</>
                                    </Accordion.Collapse>
                                </Card>
                            </div>
                        );
                    })}
                </Accordion>
            </div>
        </div>
    );
};
export default PlansManagementWrapper;
