import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputWithSearch from '../../../InputWithSearch/InputWithSearch';
import DefaultLightBox from '../../../UI/DefaultLightBox';
import './nutritionLibrary.scss';
import MoveIcon from '../../../../assets/icons/MoveIcon';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
const NutritionLibrary = () => {
  const data = [
    { id: 1, title: 'Eath vegetables' },
    { id: 2, title: 'Eat fruit' },
  ];
  return (
    <DefaultLightBox>
      <Row className={`p-3 relative align-item-center`}>
        <Col>
          <p className="m-0">Nutrition library</p>
        </Col>
        <Col className={`secondaryText`}>
          <span>Add new habit</span>
        </Col>
        <Col md={12} className="pt-3">
          <InputWithSearch placeholder={'Search library'} />
        </Col>
      </Row>
      {data &&
        data.map((data, index) => (
          <div className="nutritionLibrary" key={index}>
            <div className="d-flex align-item-center">
              <MoveIcon />
              <p className="m-0">{data.title}</p>
            </div>
            <DropdownButton title="" drop={`start`} className={`menuDots`}>
              <Dropdown.Item href="#">Action</Dropdown.Item>
            </DropdownButton>
          </div>
        ))}
    </DefaultLightBox>
  );
};
export default NutritionLibrary;
