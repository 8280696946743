import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Accordion from 'react-bootstrap/Accordion';

import Loader from '../Loader/Loader';
import InputWithSearch from '../InputWithSearch/InputWithSearch';
import DefaultLightBox from '../UI/DefaultLightBox';

import Category from './ExercisesAccordionUi/Category';

import {
  addCategory,
  editCategory,
  deleteCategory,
  addExercise,
  editExercise,
  removeExercise
} from '../../redux/actions/ExercisesAction';

const ExercisesVideoLibrary = ({ searchValue }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [activeKey, setActiveKey] = useState('1');
  const [trainersData, setTrainersData] = useState([]);

  const {
    getExercises: { data, loading }
  } = useSelector(({ exercises }) => exercises);

  const {
    getTrainers: { data: trainers },
    getUsers: { data: users }
  } = useSelector(({ users }) => users);

  useEffect(() => {
    if (trainers.length && users.length) {
      const processedData = trainers.map(trainer => {
        const user = users.find(user => user.user_id === trainer.trainer);

        return {
          ...trainer,
          name: `${user.first_name} ${user.last_name}`
        }
      });

      setTrainersData(processedData)
    }
  }, [trainers, users])

  const onSearchSubmitHandle = query => {
    history.push(`/exercises?search=${query}`);
  }

  const handleAddCategory = () => {
    dispatch(addCategory({ name: 'New category' }));
  }

  const onCategoryDeleteHandle = id => {
    if (window.confirm('Are you sure?'))
      dispatch(deleteCategory(id));
  }

  const onCategoryChangeHandle = (id, value) => {
    const data = {
      name: value
    }

    dispatch(editCategory({ id, data }))
  }

  const onAddExerciseHandle = (categoryId, name, file) => {
    var formData = new FormData();

    formData.append('category', categoryId);
    formData.append('name', name);
    formData.append('video', file);

    dispatch(addExercise(formData));
  }

  const onExerciseEditHandle = (id, data) => {
    dispatch(editExercise({ id, data }));
  }

  const onExerciseDeleteHandle = id => {
    dispatch(removeExercise(id));
  }

  return (
    <>
      <DefaultLightBox>
        <Row className={`p-3`}>
          <Col>
            <p>Video Library</p>
          </Col>
          <Col className={`text-end`}>
            <Button className={`whiteBtn px-4`} onClick={handleAddCategory}>+ Add category</Button>{' '}
          </Col>
          <Col md={12}>
            <InputWithSearch defaultValue={searchValue} placeholder={'Search video'} onSubmit={onSearchSubmitHandle} />
          </Col>
        </Row>

        <div className="ExercisesVideoLibrary relative">
          <Accordion defaultActiveKey="0" activeKey={activeKey}>
            {data.map((data, index) =>
              <Category
                data={data}
                trainers={trainersData}
                index={index}
                activeKey={activeKey}
                setActiveKey={setActiveKey}
                key={data.id}
                onChange={onCategoryChangeHandle}
                onDelete={onCategoryDeleteHandle}
                onAddExercise={onAddExerciseHandle}
                onEditExercise={onExerciseEditHandle}
                onDeleteExercise={onExerciseDeleteHandle}
              />)}
            {loading && <Loader className="flexibleLoader" />}
            {!data.length && loading && <Loader className="fullPageLoader" />}
          </Accordion>
        </div>
      </DefaultLightBox>
    </>
  );
};

export default ExercisesVideoLibrary;
