import React from 'react';
import Form from 'react-bootstrap/Form';
const ExerciseAndNutrition = () => {
  return (
    <div className="w-100 p-3">
      <Form.Select size="sm" className="dropdown w-100">
        <option>Weigh Loss</option>
      </Form.Select>
    </div>
  );
};
export default ExerciseAndNutrition;
