import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';

import UserList from '../../components/Users/UserList/UserList';
import UserProfile from '../../components/Users/UserProfile';

import {
  getUsers
} from '../../redux/actions/UsersAction';

export default function Users() {
  const { id } = useParams();

  const dispatch = useDispatch();
  const location = useLocation();

  // const [selectedUser, setSelectedUser] = useState('');

  /* const {
    getUsers: { data },
  } = useSelector(({ users }) => users); */

  const searchQuery = new URLSearchParams(location.search).get('search') || ''

  useEffect(() => {
    dispatch(getUsers(searchQuery));
  }, [searchQuery]);

  /* useEffect(() => {
    if (data && id && !selectedUser)
      setSelectedUser(data.find(user => user.user_id == id))
  }, [data]); */

  return (
    <div>
      {!!id
        ? <UserProfile id={id} />
        : <UserList />
      }
    </div>
  );
}
