import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import { Accordion } from 'react-bootstrap';
import CustomToggleExercises from './CustomToggleExercises';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import VideoLibraryLevelThree from './VideoLibraryLevelThree';
import InputTitle from '../../UI/InputTitle';

const VideoLibraryLevelTwo = ({ exercise, trainers, index, onEdit, onDelete }) => {
  const [activeKey, setActiveKey] = useState('1');
  const [name, setName] = useState(exercise.name);

  const onDeleteHandle = id => {
    if (window.confirm('Are you sure?'))
      onDelete(id)
  }

  const onNameChangeHandle = event => {
    setName(event.target.value);
  }

  const onNameBlurHandle = () => {
    if (exercise.name !== name)
      onEdit(exercise.id, { name });
  }

  const onNameKeyDownHandle = event => {
    if (event.keyCode === 13 && exercise.name !== name)
      onEdit(exercise.id, { name });
  }

  return (
    <>
      <Accordion defaultActiveKey="0">
        <Card className={`border-0 VideoLevelTwo`} key={index}>
          <Card.Header className={`p-0`}>
            <CustomToggleExercises setActiveKey={setActiveKey} eventKey={index} activeKey={activeKey} />

            <Row className={`inputVideoWrapperWidth`}>
              <Col md={5} className={`align-item-center d-flex my-1`}>
                <InputTitle className={`me-2`}>NAME</InputTitle>
                <Form.Control type="text" value={name} onChange={onNameChangeHandle} onBlur={onNameBlurHandle} onKeyDown={onNameKeyDownHandle} placeholder="Normal text" />
              </Col>
              <Col md={7} className={`align-item-center d-flex my-1`}>
                <InputTitle className={`me-2`}>Video link</InputTitle>
                <Form.Control type="text" defaultValue={exercise.video} readOnly />
              </Col>
            </Row>

            <div className={`setIconPosition`}>
              <DropdownButton title="" drop={`start`} className={`menuDots`}>
                <Dropdown.Item href="#" onClick={() => onDeleteHandle(exercise.id)}>Delete exercise</Dropdown.Item>
              </DropdownButton>
            </div>
          </Card.Header>

          <Accordion.Collapse eventKey={index}>
            <VideoLibraryLevelThree exercise={exercise} trainers={trainers} onEdit={onEdit} />
          </Accordion.Collapse>
        </Card>
      </Accordion>
      <hr className="my-0" />
    </>
  );
};

export default VideoLibraryLevelTwo;
