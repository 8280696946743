import { all, call, put, takeLatest } from 'redux-saga/effects';

import { Axios } from '../../api/axios';
import { getSimplifiedError } from '../../utils/error';
import { toast } from 'react-toastify';

import {
  ADD_CATEGORY_REQUEST,
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY_ERROR,

  EDIT_CATEGORY_REQUEST,
  EDIT_CATEGORY_SUCCESS,
  EDIT_CATEGORY_ERROR,

  DELETE_CATEGORY_REQUEST,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_ERROR,

  GET_EXERCISES_REQUEST,
  GET_EXERCISES_SUCCESS,
  GET_EXERCISES_ERROR,

  ADD_EXERCISE_REQUEST,
  ADD_EXERCISE_SUCCESS,
  ADD_EXERCISE_ERROR,

  EDIT_EXERCISE_REQUEST,
  EDIT_EXERCISE_SUCCESS,
  EDIT_EXERCISE_ERROR,

  DELETE_EXERCISE_REQUEST,
  DELETE_EXERCISE_SUCCESS,
  DELETE_EXERCISE_ERROR,
} from '../reducers/ExercisesReducer';

async function addCategory(payload) {
  return await Axios.post(`/category/`, payload);
}

function* handleAddCategory({ payload }) {
  try {
    const response = yield call(addCategory, payload);

    if (response) {
      yield put({
        type: ADD_CATEGORY_SUCCESS,
        data: response,
      });
    }
  } catch (error) {
    yield put({
      type: ADD_CATEGORY_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}


async function editCategory(payload) {
  return await Axios.patch(`/category/${payload.id}/`, payload.data);
}

function* handleEditCategory({ payload }) {
  try {
    const response = yield call(editCategory, payload);

    if (response) {
      yield put({
        type: EDIT_CATEGORY_SUCCESS,
        data: payload
      });
    }
  } catch (error) {
    yield put({
      type: EDIT_CATEGORY_ERROR,
      error: getSimplifiedError(error)
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}


async function deleteCategory(payload) {
  return await Axios.delete(`/category/${payload}/`);
}

function* handleDeleteCategory({ payload }) {
  try {
    yield call(deleteCategory, payload);
    yield put({
      type: DELETE_CATEGORY_SUCCESS,
      data: payload
    });
  } catch (error) {
    yield put({
      type: DELETE_CATEGORY_ERROR,
      error: getSimplifiedError(error)
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

async function getExercises(query) {
  return await Axios.get(`/category/?name=${query}`);
}

function* handleGetExercises({ payload }) {
  try {
    const response = yield call(getExercises, payload);

    if (response) {
      yield put({
        type: GET_EXERCISES_SUCCESS,
        data: response.results,
      });
    }
  } catch (error) {
    yield put({
      type: GET_EXERCISES_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}


async function addExercise(payload) {
  return await Axios.post(`/exercise/`, payload);
}

function* handleAddExercise({ payload }) {
  try {
    const response = yield call(addExercise, payload);

    if (response) {
      yield put({
        type: ADD_EXERCISE_SUCCESS,
        data: response,
      });
    }
  } catch (error) {
    yield put({
      type: ADD_EXERCISE_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}


async function deleteExercise(payload) {
  return await Axios.delete(`/exercise/${payload}/`);
}

function* handleDeleteExercise({ payload }) {
  try {
    yield call(deleteExercise, payload);
    yield put({
      type: DELETE_EXERCISE_SUCCESS,
      data: payload
    });
  } catch (error) {
    yield put({
      type: DELETE_EXERCISE_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}


async function editExercise(payload) {
  return await Axios.patch(`/exercise/${payload.id}/`, payload.data);
}

function* handleEditExercise({ payload }) {
  try {
    const response = yield call(editExercise, payload);

    if (response) {
      yield put({
        type: EDIT_EXERCISE_SUCCESS,
        data: response,
      });
    }
  } catch (error) {
    yield put({
      type: EDIT_EXERCISE_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}


export default all([
  takeLatest(ADD_CATEGORY_REQUEST, handleAddCategory),
  takeLatest(EDIT_CATEGORY_REQUEST, handleEditCategory),
  takeLatest(DELETE_CATEGORY_REQUEST, handleDeleteCategory),

  takeLatest(GET_EXERCISES_REQUEST, handleGetExercises),

  takeLatest(ADD_EXERCISE_REQUEST, handleAddExercise),
  takeLatest(EDIT_EXERCISE_REQUEST, handleEditExercise),
  takeLatest(DELETE_EXERCISE_REQUEST, handleDeleteExercise),
]);
