import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import FilledArrow from '../../assets/icons/FilledArrow';

import {
  addNewUserCorelation,
  createUserCorelation,
  deleteUserCorelation,
  updateUserCorelation,
} from '../../redux/actions/PlansAction';

const QuestionsPicker = ({ id, userType }) => {
  const [response, setResponse] = useState([]);
  const [questionsResponse, setQuestionsResponse] = useState([]);

  const [selectedType, setSelectedType] = useState(null);
  const [allQuestions, setAllQuestions] = useState(null);

  const dispatch = useDispatch();

  const {
    getUserCorelation: { data: getCorelation },
  } = useSelector(({ plan }) => plan);

  const {
    getQuestion: { data: questionData },
  } = useSelector(({ quizz }) => quizz);

  useEffect(() => {
    if (getCorelation) {
      const selectedData = [];

      getCorelation?.results?.map(data => {
        data.responses?.map(r =>
          selectedData.push({ userType: data.user_type, question: r.question, option: r.option }),
        );
      });

      setQuestionsResponse(selectedData);
    }
  }, [getCorelation]);

  useEffect(() => {
    const updatedQuestion = questionData.map(question => {
      return {
        ...question,

        options: question.options.map(option => {
          return {
            ...option,
            isActive: false,
          };
        }),
      };
    });

    setAllQuestions(updatedQuestion);
  }, [questionData]);

  const addModifyViewDialog = id => {
    return (
      <Popover id={`popover-positioned-bottom`}>
        <Popover.Body>
          {allQuestions &&
            allQuestions.map((question, QuestionIndex) => {
              return (
                <div className="planText" key={QuestionIndex}>
                  <div className="textDark">Question {QuestionIndex + 1}</div>
                  <div className="plansOptions">
                    {question &&
                      question.options.map((option, index) => {
                        return (
                          <span
                            key={index}
                            className={option.isActive ? 'active' : ''}
                            onClick={handleOptionClick(index, QuestionIndex, userType, option.id, question.id)}
                          >
                            {String.fromCharCode(65 + index)}
                          </span>
                        );
                      })}
                  </div>
                </div>
              );
            })}
        </Popover.Body>
      </Popover>
    );
  };

  const handleOptionClick = (optionIndex, questionIndex, userTypeId, optionId, questionId) => () => {
    
    const newQuestions = [...allQuestions];
    console.log(newQuestions);
    newQuestions[questionIndex].options[optionIndex].isActive = !newQuestions[questionIndex].options[optionIndex].isActive;

    setAllQuestions(newQuestions);

    const index = response.findIndex(r => r.option === optionId && r.question === questionId);
    const resIndex = questionsResponse.findIndex(
      r => r.userType === userTypeId && r.question === questionId && r.option === optionId,
    );

    if (resIndex !== -1 && questionsResponse.length) {
      questionsResponse.splice(resIndex, 1);
      setQuestionsResponse([...questionsResponse]);
    } else {
      setQuestionsResponse([
        ...questionsResponse,
        {
          userType: userTypeId,
          question: questionId,
          option: optionId,
        },
      ]);
    }

    if (index !== -1 && response.length) {
      setSelectedType(userTypeId);
      response.splice(index, 1);
      setResponse([...response]);
    } else {
      setSelectedType(userTypeId);
      setResponse([
        ...response,
        {
          question: questionId,
          option: optionId,
        },
      ]);
    }

    const getExistIndex = getCorelation?.results.findIndex(r => r.user_type === userTypeId);

    if (getExistIndex !== -1) {
      console.log('@@@@ => ', newQuestions[questionIndex].options[optionIndex].isActive);

      if (newQuestions[questionIndex].options[optionIndex].isActive) {
        dispatch(
          addNewUserCorelation({
            userTypeId: userTypeId,
            data: { question: questionId, option: optionId },
          }),
        );
      } else {
        console.log('delete', optionId);
        // dispatch(deleteUserCorelation());
      }
    }
    // dispatch(deleteUserCorelation({ id: 59 }));
  };

  const handleToggle = (show, exerciseId) => {
    
    /*CHOOSE QUESTION function*/
    if (!show && userType && response.length) {
      console.log('@@@@', getCorelation?.results);
      const getExistIndex = getCorelation?.results.findIndex(r => r.user_type === userType);
      if (getExistIndex === -1) {
        const requestPayload = {
          user_type: selectedType,
          responses: response,
        };
        /* CHOOSE QUESTION First time POST API */
        /* TODO: add new corelation */
        dispatch(createUserCorelation(requestPayload));
      } else {
        const updateRes = [];
        questionsResponse.map(data => {
          if (data.userType === selectedType) {
            const { userType, ...rest } = data;
            updateRes.push(rest);
          }
        });
        const requestPayload = {
          user_type: selectedType,
          responses: updateRes,
        };
        /*CHOOSE QUESTION PUT API */
        // dispatch(updateUserCorelation({ id: getCorelation?.results[getExistIndex].id, data: requestPayload }));
      }
      return;
    }
    const shortQuestionData = questionData?.sort(function (a, b) {
      return parseFloat(a.id) - parseFloat(b.id);
    });

    const updatedQuestion = shortQuestionData.map(question => {
      return {
        ...question,
        options: question.options.map(option => {
          const filterQuestionOption = questionsResponse.find(
            i => i.question === question.id && i.option === option.id && i.userType === userType,
          );
          return {
            ...option,
            isActive: !!filterQuestionOption,
          };
        }),
      };
    });

    setAllQuestions(updatedQuestion);
  };

  const getSelectedOptions = selectedQuestionId => {
    console.log(selectedQuestionId);
    const filterQuestion = questionsResponse.filter(q => q.userType === userType);
    // console.log({ filterQuestion });
    if (!filterQuestion.length) return `Choose question`;
    let str = '';
    allQuestions.forEach(question => {
      const foundItems = questionsResponse.filter(
        item => item.question === question.id && item.userType === userType,
      );
      if (foundItems.length) {
        const questionId = foundItems[0].question;
        const questionIndex = allQuestions.findIndex(q => q.id === questionId);
        str += `Question ${questionIndex + 1}`;
        foundItems.forEach(obj => {
          const { option } = obj;
          const questionObj = allQuestions[questionIndex];
          const { options = [] } = questionObj;
          const optionIndex = options.findIndex(opt => opt.id === option);
          str += ` ${String.fromCharCode(65 + optionIndex)}, `;
        });
      }
    });

    return str;
  };

  return (
    <OverlayTrigger
      rootClose
      trigger="click"
      placement="bottom"
      overlay={addModifyViewDialog(id)}
      onToggle={show => handleToggle(show, id)}
    >
      <div className="dropDownPlan">
        <div className="d-flex align-item-center">
          <div className="pr-2 d-flex align-item-center">
            <div className={`questionText`}>{getSelectedOptions(id)}</div>
          </div>
          <FilledArrow />
        </div>
      </div>
    </OverlayTrigger>
  );
}

export default QuestionsPicker;
