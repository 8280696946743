import {
  GET_USERS_REQUEST,
  GET_USER_REQUEST,
  GET_USER_DATA_REQUEST,
  GET_USER_PERCENTAGE_REQUEST,
  UPDATE_USER_REQUEST,
  GET_TRAINERS_REQUEST
} from '../reducers/UsersReducer';

export const getUsers = (payload) => ({ type: GET_USERS_REQUEST, payload });
export const getUser = (payload) => ({ type: GET_USER_REQUEST, payload });
export const getUserData = (payload) => ({ type: GET_USER_DATA_REQUEST, payload });
export const getUserPercentage = (payload) => ({ type: GET_USER_PERCENTAGE_REQUEST, payload });
export const updateUser = (payload) => ({ type: UPDATE_USER_REQUEST, payload });
export const getTrainers = (payload) => ({ type: GET_TRAINERS_REQUEST, payload });
