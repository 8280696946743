import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import UserMessageList from './UserList/UserList';
import TabBar from './TabBar';

const MessagesComponents = () => {
  const [selectedTab, setSelectedTab] = useState('');

  useFirestoreConnect([
    { collection: 'Groups' }
  ]);

  const { Groups } = useSelector(state => state.firestore.ordered);

  useEffect(() => {
    if (Groups && Groups.length)
      setSelectedTab(Groups[0].name)
  }, [Groups]);

  const chatUsers = Groups ? Groups.find(group => group.name === selectedTab)?.members : [];

  return (
    <>
      <Row className="community-chat mb-4">
        <Col lg={7} className="pt-3">
          <TabBar
            groups={Groups || []}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        </Col>

        <Col lg={5} className="pt-3">
          <UserMessageList data={chatUsers} />
        </Col>
      </Row>
    </>
  );
};

export default MessagesComponents;
