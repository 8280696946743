import React from 'react';
import MoveIcon from '../../../../../assets/icons/MoveIcon';
import ExerciseTable from './ExerciseTable';
import './exerciseTableGroup.scss';

export default function ExerciseTableGroup({ groupData = [], handleChecked, handleUpdate }) {
  return (
    <tr className="groupTR">
      <td colSpan="5">
        <div className="d-flex align-item-center">
          <div className="px-1">
            <MoveIcon />
          </div>
          <div className="groupList">
            {groupData &&
              groupData.map((data) => (
                <ExerciseTable
                  className="inlineTable"
                  data={data}
                  group={true}
                  key={data.id}
                  handleChecked={handleChecked}
                  handleUpdate={handleUpdate}
                />
              ))}
          </div>
          <div className="px-1">
            <MoveIcon />
          </div>
        </div>
      </td>
    </tr>
  );
}
