import React from 'react';

import { Formik } from "formik";
import * as yup from "yup";

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from '../../../../../Atoms/Button/index';
import whatsAppIcon from '../../../../../assets/icons/whatsappIcon.png';
import barGraphIcon from '../../../../../assets/icons/barGraphIcon.png';
import runningMan from '../../../../../assets/icons/running-active.svg';
import UserProfile from '../../../../../assets/images/placeholder1.png';

import './style.scss';

const schema = yup.object().shape({
  first_name: yup.string(),
  last_name: yup.string(),
  email: yup.string().email().required(),
  phone_number: yup.string(),
  date_joined: yup.string(),
  age: yup.string()
});

const UserInformation = ({ user, onUpdate }) => {
  const initialValues = {
    first_name: user.first_name || '',
    last_name: user.last_name || '',
    email: user.email,
    phone_number: user.phone_number || '',
    date_joined: new Date(user.date_joined).toISOString().substr(0, 10) || '',
    age: user.age || ''
  }

  const userTitles = [
    {
      icon: whatsAppIcon,
      title: 'Has completed quizz',
      show: user.completed_quiz > 0
    },
    {
      icon: barGraphIcon,
      title: 'Has set goals',
      show: user.saved_goals > 0
    },
    {
      icon: runningMan,
      title: 'Has saved movement videos',
      show: user.saved_movements > 0
    }
  ];

  const onSubmitHandle = fields => {
    onUpdate({ ...fields, date_joined: new Date(fields.date_joined).toISOString() });
  }

  return (
    <div className="userInformationWrapper">
      <div className="infoWrapper">
        <div className="profileImage">
          <img src={user.profile_picture || UserProfile} alt="profile" className="profileImage" />
        </div>
        <div className="profileContent">
          <div className="userName">{user.first_name} {user.last_name}</div>
          <div className="category">{user.user_type && user.user_type.name}</div>

          {userTitles.filter(item => item.show).map((item, index) => {
            const { icon, title } = item;

            return (
              <div className="categoryInfo" key={index}>
                <img src={icon} alt="icon" />
                <span className="title">{title}</span>
              </div>
            );
          })}
        </div>
      </div>

      <div className="userInfoFormWrapper">
        <Formik
          validationSchema={schema}
          onSubmit={onSubmitHandle}
          initialValues={initialValues}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ handleSubmit, handleChange, handleBlur, values, errors }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col className="col-xl-6 col-lg-12 col-md-6 col-12">
                    <Form.Label>First name</Form.Label>
                    <Form.Control type="text" name="first_name" value={values.first_name} onChange={handleChange} />
                  </Col>
                  <Col className="col-xl-6 col-lg-12 col-md-6 col-12">
                    <Form.Label>Last name</Form.Label>
                    <Form.Control name="last_name" value={values.last_name} onChange={handleChange} />
                  </Col>
                </Row>
                <Row>
                  <Col className="col-xl-6 col-lg-12 col-md-6 col-12">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control name="email" value={values.email} isInvalid={!!errors.email} onChange={handleChange} />
                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                  </Col>
                  <Col className="col-xl-6 col-lg-12 col-md-6 col-12">
                    <Form.Label>Phone number</Form.Label>
                    <Form.Control name="phone_number" value={values.phone_number} onChange={handleChange} />
                  </Col>
                </Row>
                <Row>
                  <Col className="col-xl-6 col-lg-12 col-md-6 col-12">
                    <Form.Label>Age</Form.Label>
                    <Form.Control name="age" value={values.age} onChange={handleChange} />
                  </Col>
                  <Col className="col-xl-6 col-lg-12 col-md-6 col-12">
                    <Form.Label>Registration date</Form.Label>
                    <Form.Control type="date" name="date_joined" value={values.date_joined} onChange={handleChange} />
                  </Col>
                </Row>
                <Row className="align-item-center">
                  <Col>
                    <Button type="submit" htmlType="submit" text="Save changes" />
                  </Col>
                  {/* <Col>
                    <Button text="delete user" />
                  </Col>
                  <Col className="links">
                    <span>give user admin credentials</span>
                    <span>reset password</span>
                  </Col> */}
                </Row>
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  );
};

export default UserInformation;
