import React from 'react';
import DummyUser from '../../../../../assets/images/userDummy.png';

const UserBox = ({ user }) => {
  const { name, img } = user;

  return (
    <>
      <div className="col-6 col-md-4 col-lg-3">
        <div className="userBox">
          <div className="userImages">
            <img src={img} />
          </div>
          <div className="userName">{name}</div>
        </div>
      </div>
    </>
  );
};

export default UserBox;
