import React from 'react';
import { useSelector } from 'react-redux';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import placeholder from '../../assets/images/placeholder1.png';

import { makeStyles } from '@material-ui/core/styles';
import './newUsersTable.css';

export default function NewUsersTable() {
  const {
    getUsers: { data },
  } = useSelector(({ users }) => users);

  const useStyles = makeStyles({
    root: {
      width: 'auto',
      margin: '0px',
    },
    table: {
      margin: '0px',
      padding: '15px',
      fontFamily: 'Montserrat, serif',
      borderRadius: '10px 10px 0px 0px',
    },
    header: {
      textTransform: 'uppercase',
    },
    tableCell: {
      padding: '15px 20px',
      fontSize: '11px',
      fontWeigh: '500',
      backgroundColor: '#F4F3EE',
      color: '#4C443F',
    },
    userName: {
      color: '#62A8A4',
    },
    icon: {
      width: '10px',
      height: '13.5px',
    },
    tableData: {
      padding: '15px 20px',
      backgroundColor: '#FAF9F3',
      fontSize: '12px',
    },
    filter: {
      display: 'inline',
    },
  });

  const classes = useStyles();

  return (
    <TableContainer>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className={`${classes.tableCell} ${classes.header}`}>User</TableCell>
            <TableCell className={`${classes.tableCell} ${classes.header} ${classes.filters}`} align="right">
              <div className="userTable-dropdown-plan text-right">
                <label htmlFor="users" className="usersTable-dropdowns-title">
                  Show:
                </label>
                <select className="usersTable-select" name="users" id="users">
                  <option value="user">User Type</option>
                </select>
              </div>
            </TableCell>
            <TableCell className={`${classes.tableCell} ${classes.header} ${classes.filters}`} align="right">
              <div className="userTable-dropdown-plan">
                <label htmlFor="usersTable-plans" className="usersTable-dropdowns-title">
                  Plans:
                </label>
                <select className="usersTable-select" name="usersTable-plans" id="users">
                  <option value="all">All</option>
                </select>
              </div>
            </TableCell>

            <TableCell className={`${classes.tableCell} ${classes.header}`} align="center">
              Movement
            </TableCell>
            <TableCell className={`${classes.tableCell} ${classes.header}`} align="center">
              Nutrition
            </TableCell>
            <TableCell className={`${classes.tableCell} ${classes.header}`} align="center">
              Self Care
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((data) => (
            <TableRow key={data.id}>
              <TableCell component="th" scope="row" className={`${classes.userName} ${classes.tableData}`}>
                {<img className="users-table-image" src={data.image ? data.image : placeholder} alt="profile" width="40" height="40"></img>} {data.first_name} {data.last_name}
              </TableCell>
              <TableCell align="right" className={`${classes.tableCell} ${classes.tableData}`}>
                {data.user_type ? data.user_type : 'N/a'}
              </TableCell>
              <TableCell align="right" className={`${classes.tableCell} ${classes.tableData}`}>
                {data.movement_plan ? data.movement_plan : 'N/a'}
              </TableCell>
              <TableCell align="center" className={`${classes.tableCell} ${classes.tableData}`}>
                {data.movement ? data.movement.toFixed(2) : 'N/a'}
              </TableCell>
              <TableCell align="center" className={`${classes.tableCell} ${classes.tableData}`}>
                {data.nutrition ? data.nutrition.toFixed(2) : 'N/a'}
              </TableCell>
              <TableCell align="center" className={`${classes.tableCell} ${classes.tableData}`}>
                {data.self_care ? data.self_care.toFixed(2) : 'N/a'}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
