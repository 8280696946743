// import { orderBy } from 'lodash';

export const GET_PLANS_STATISTIC_REQUEST = 'GET_PLANS_STATISTIC_REQUEST';
export const GET_PLANS_STATISTIC_SUCCESS = 'GET_PLANS_STATISTIC_SUCCESS';
export const GET_PLANS_STATISTIC_ERROR = 'GET_PLANS_STATISTIC_ERROR';

export const GET_TOTAL_DATA_REQUEST = 'GET_TOTAL_DATA_REQUEST';
export const GET_TOTAL_DATA_SUCCESS = 'GET_TOTAL_DATA_SUCCESS';
export const GET_TOTAL_DATA_ERROR = 'GET_TOTAL_DATA_ERROR';

const block = {
  loading: false,
  error: '',
  success: false,
};

const initialState = {
  getPlansStatistic: { ...block, data: {} },
  getTotalData: { ...block, data: {} }
};

export const StatisticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PLANS_STATISTIC_REQUEST:
      return {
        ...state,
        getPlansStatistic: { ...state.getPlansStatistic, loading: true },
      };
    case GET_PLANS_STATISTIC_SUCCESS:
      return {
        ...state,
        getPlansStatistic: {
          ...state.getPlansStatistic,
          loading: false,
          success: true,
          data: action.data,
        },
      };
    case GET_PLANS_STATISTIC_ERROR:
      return {
        ...state,
        getPlansStatistic: {
          ...state.getPlansStatistic,
          loading: false,
          error: action.error,
        },
      };

    case GET_TOTAL_DATA_REQUEST:
      return {
        ...state,
        getTotalData: { ...state.getTotalData, loading: true },
      };
    case GET_TOTAL_DATA_SUCCESS:
      return {
        ...state,
        getTotalData: {
          ...state.getTotalData,
          loading: false,
          success: true,
          data: action.data,
        },
      };
    case GET_TOTAL_DATA_ERROR:
      return {
        ...state,
        getTotalData: {
          ...state.getTotalData,
          loading: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
};
