import { orderBy } from 'lodash';

export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_ERROR = 'GET_USERS_ERROR';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_ERROR = 'GET_USER_ERROR';

export const GET_USER_DATA_REQUEST = 'GET_USER_DATA_REQUEST';
export const GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS';
export const GET_USER_DATA_ERROR = 'GET_USER_DATA_ERROR';

export const GET_USER_PERCENTAGE_REQUEST = 'GET_USER_PERCENTAGE_REQUEST';
export const GET_USER_PERCENTAGE_SUCCESS = 'GET_USER_PERCENTAGE_SUCCESS';
export const GET_USER_PERCENTAGE_ERROR = 'GET_USER_PERCENTAGE_ERROR';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';

export const GET_TRAINERS_REQUEST = 'GET_TRAINERS_REQUEST';
export const GET_TRAINERS_SUCCESS = 'GET_TRAINERS_SUCCESS';
export const GET_TRAINERS_ERROR = 'GET_TRAINERS_ERROR';

const block = {
  loading: false,
  error: '',
  success: false
};

const initialState = {
  getUsers: { ...block, data: [] },
  getTrainers: { ...block, data: [] },
  getUser: { ...block, data: null },
  getUserData: { ...block, data: null },
  getUserPercentage: { ...block, data: null }
};

export const UsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS_REQUEST:
      return {
        ...state,
        getUsers: { ...state.getUsers, loading: true },
      };
    case GET_USERS_SUCCESS:
      const updatedUsersData = action.data.map(users => {
        return {
          ...users,
          // users: orderBy(question.options, 'order', 'asc'),
        };
      });
      return {
        ...state,
        getUsers: {
          ...state.getUsers,
          loading: false,
          success: true,
          data: updatedUsersData,
        },
      };
    case GET_USERS_ERROR:
      return {
        ...state,
        getUsers: {
          ...state.getUsers,
          loading: false,
          error: action.error,
        },
      };

    case GET_USER_REQUEST:
      return {
        ...state,
        getUser: {
          ...state.getUser,
          loading: true
        }
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        getUser: {
          ...state.getUser,
          loading: false,
          success: true,
          data: action.data,
        }
      };
    case GET_USER_ERROR:
      return {
        ...state,
        getUser: {
          ...state.getUser,
          loading: false,
          error: action.error,
        }
      };

    case GET_USER_DATA_REQUEST:
      return {
        ...state,
        getUserData: {
          ...state.getUserData,
          loading: true
        }
      };
    case GET_USER_DATA_SUCCESS:
      return {
        ...state,
        getUserData: {
          ...state.getUserData,
          loading: false,
          success: true,
          data: action.data,
        }
      };
    case GET_USER_DATA_ERROR:
      return {
        ...state,
        getUserData: {
          ...state.getUserData,
          loading: false,
          error: action.error,
        }
      };

    case GET_USER_PERCENTAGE_REQUEST:
      return {
        ...state,
        getUserPercentage: {
          ...state.getUserPercentage,
          loading: true
        }
      };
    case GET_USER_PERCENTAGE_SUCCESS:
      return {
        ...state,
        getUserPercentage: {
          ...state.getUserPercentage,
          loading: false,
          success: true,
          data: action.data,
        }
      };
    case GET_USER_PERCENTAGE_ERROR:
      return {
        ...state,
        getUserPercentage: {
          ...state.getUserPercentage,
          loading: false,
          error: action.error,
        }
      };

    case UPDATE_USER_REQUEST:
      return {
        ...state,
        User: {
          ...state.getUser,
          loading: true
        }
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        getUser: {
          ...state.getUser,
          loading: false,
          success: true,
          data: action.data,
        }
      };
    case UPDATE_USER_ERROR:
      return {
        ...state,
        getUser: {
          ...state.getUser,
          loading: false,
          error: action.error,
        }
      };

    case GET_TRAINERS_REQUEST:
      return {
        ...state,
        getTrainers: {
          ...state.getTrainers,
          loading: true
        }
      };
    case GET_TRAINERS_SUCCESS:
      return {
        ...state,
        getTrainers: {
          ...state.getTrainers,
          loading: false,
          success: true,
          data: action.data.results
        }
      };
    case GET_TRAINERS_ERROR:
      return {
        ...state,
        getTrainers: {
          ...state.getTrainers,
          loading: false,
          error: action.error
        }
      };

    default:
      return state;
  }
};
