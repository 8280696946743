import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';

import DefaultLightBox from '../UI/DefaultLightBox';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';

import UserMessageList from './UserMessageList/UserMessageList';
import UsersMessage from './UsersMessage/UsersMessage';

import { getUsers } from '../../redux/actions/UsersAction';

import './styles.scss';

const MessagesComponents = ({ selectedUserId }) => {
  const dispatch = useDispatch();
  const firestore = useFirestore();

  const [ chatsList, setChatsList ] = useState([]);

  const path = `users/${9}/chats`;

  useFirestoreConnect([
    { collection: path },
    { collection: `users/${selectedUserId}/chats` },
  ]);

  const chatsListRaw = useSelector(state => state.firestore.ordered);

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  const {
    getUsers: { data: users }
  } = useSelector(({ users }) => users);

  useEffect(() => {
    if (Object.keys(chatsListRaw).length && users.length) {
      setChatsList(chatsListRaw[path].map(chatList => ({ ...chatList, secondaryUser: { ...chatList.secondaryUser, userPicture: users.find(user => user.user_id == chatList.secondaryUser.userID)?.image } }) ))
    }
  }, [chatsListRaw, users]);

  // Debug: sending a message from secondary user:
  // 
  /* const onClickHandle = async () => {
    const message = "Hello from the client!";

    firestore
      .collection(`users/${selectedUserId}/chats/`)
      .doc(chatID)
      .update({
        message,
        messageType: 'text',
        updatedAt: firestore.FieldValue.serverTimestamp(),
        unReadMessagesCount: 0
      });

    const unReadMessagesCount = firestore
      .collection(`users/${9}/chats/`)
      .doc(chatID)
      .get().unReadMessagesCount;

    console.log(`current unReadMessagesCount: ${unReadMessagesCount}`);

    firestore
      .collection(`users/${9}/chats/`)
      .doc(chatID)
      .update({
        message,
        messageType: 'text',
        updatedAt: firestore.FieldValue.serverTimestamp(),
        unReadMessagesCount: 1
      });

    firestore
      .collection(`chats/${chatID}/messages`)
      .add({
        message,
        messageType: "text",
        createdAt: firestore.FieldValue.serverTimestamp(),
        sender: selectedUserId,
        receiver: 9
    });
  } */

  const chatID = chatsList.find(chat => chat.secondaryUser.userID == selectedUserId)?.id;

  return (
    <Row className="message-center">
      {/* Debug: to send a message from a secondary user */}
      {/* <button type="button" onClick={onClickHandle} style={{ position: 'fixed', width: 48, height: 48, borderRadius: '50%', left: 10, bottom: 10 }}>Test</button> */}

      <Col lg={7} className="d-flex pt-3 h-100">
        {(users.length && selectedUserId)
          ? <UsersMessage users={users} chatID={chatID} selectedUserId={selectedUserId} />
          : (
            <DefaultLightBox className="userMessageWrapper">
              <Card className={`border-0`}>
                <Card.Header className={`userOpen`}>
                  Exercise feedback
                </Card.Header>
              </Card>
              <div className="px-3 py-4">
                <p>No messages</p>
              </div>
            </DefaultLightBox>
          )
        }
      </Col>
      <Col lg={5} className="pt-3">
        <UserMessageList data={chatsList} selectedUserId={selectedUserId} />
      </Col>
    </Row>
  );
};

export default MessagesComponents;
