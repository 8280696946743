import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import ConnectNewVideo from '../../components/Exercises/ConnectNewVideo';
import ExercisesVideoLibrary from '../../components/Exercises/ExercisesVideoLibrary';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';

import { getExercises } from '../../redux/actions/ExercisesAction';
import { getTrainers, getUsers } from '../../redux/actions/UsersAction';

const Index = () => {
  const navigation = ['Exercises'];

  const dispatch = useDispatch();
  const location = useLocation()

  const searchQuery = new URLSearchParams(location.search).get('search') || ''

  useEffect(() => {
    dispatch(getTrainers());
    dispatch(getUsers());
  }, []);

  useEffect(() => {
    dispatch(getExercises(searchQuery));
  }, [searchQuery]);

  return (
    <>
      <Breadcrumb navigation={navigation} />
      <Row>
        <Col lg={7}>
          <ExercisesVideoLibrary searchValue={searchQuery} />
        </Col>

        <Col lg={5}>
          <ConnectNewVideo />
        </Col>
      </Row>
    </>
  );
};

export default Index;
