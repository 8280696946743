import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap';

import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';

import { useDispatch } from 'react-redux';
import { updateMovementType } from '../../../redux/actions/MovementTypesAction';

import GymImg from '../../../assets/images/video.png';

const MovemenentStyleBox = ({ type }) => {
  const dispatch = useDispatch();

  const [description, setDescription] = useState(type.description);

  const onDescriptionChangeHandle = evt => {
    setDescription(evt.target.value);
  }

  const onDescriptionBlurHandle = () => {
    if (type.description !== description)
      dispatch(updateMovementType({ id: type.id, description }));
  }

  const onDescriptionKeyDownHandle = event => {
    if (event.keyCode === 13 && type.description !== description)
      dispatch(updateMovementType({ id: type.id, description }));
  }

  const onFileChangeHandle = evt => {
    dispatch(updateMovementType({ id: type.id, imageRaw: evt.target.files[0] }));
  }

  return (
    <div className="plansManagementWrapper">
      <div className="nutritionAccordion">
        <Accordion defaultActiveKey="1">
          <div>
            <Card className={`border-0`}>
              <Form.Group className="p-3 inputMoment">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  style={{ height: '100px' }}
                  value={description}
                  onChange={onDescriptionChangeHandle}
                  onBlur={onDescriptionBlurHandle}
                  onKeyDown={onDescriptionKeyDownHandle}
                />
              </Form.Group>

              <Form.Group className="mb-2 p-3 inputMoment">
                <Form.Label>Image</Form.Label>

                <div className="d-flex">
                  <img
                    className="mr-3"
                    src={type.image || GymImg}
                    width="120"
                    alt="image"
                  />

                  <div className="px-3 flex-grow-1">
                    <Form.Control
                      type="file"
                      name="file"
                      onChange={onFileChangeHandle}
                    />
                  </div>
                </div>
              </Form.Group>
            </Card>
          </div>
        </Accordion>
      </div>
    </div>
  );
};

export default MovemenentStyleBox;
