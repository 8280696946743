import React from 'react';

import ActivityProgress from './ActivityProgress';
import AreaLineChart from './AreaLineChart';

import './style.scss';

const CurrentProgress = ({ userData, userPercentage }) => {
    const progress = [
        { id: 1, title: 'Movement', value: userPercentage?.movement },
        { id: 2, title: 'Nutrition', value: userPercentage?.nutrition },
        { id: 3, title: 'Self care', value: userPercentage?.self_care }
    ];

    const data = userData && userData[0] ? userData[0].weight : [];
    console.log(data.data === undefined ? null : data.data, 'data');
    return (
        <div className="currentProgress">
            <div className="trackingActivityWrapper">
                <div className="tile">Tracking activity</div>
                <div className="subtitle">Monthly</div>
            </div>

            {progress &&
                progress.map((data, index) => (
                    <ActivityProgress data={data} key={index} />
                ))}
            {data.map((program, index) => (
                <AreaLineChart
                    title={getUnit(program)}
                    data={program?.data?.length < 0 ? '' : program.data}
                    key={index}
                />
            ))}
        </div>
    );
};

function getUnit(program) {
    console.log(program, 'programme');
    switch (program.name) {
        case 'wight':
            return `Weight ${
                program?.progress.length === 0 ? '' : program?.progress[0]
            } (lbs)`;
        case 'waist':
            return `Waist circumference ${
                program?.progress.length === 0 ? '' : program?.progress[0]
            } (inch)`;
        case 'body_composition':
            return `Body composition ${
                program?.progress.length === 0 ? '' : program?.progress[0]
            }% (body fat)`;
        case 'rest_heart_rate':
            return `Resting heart rate ${
                program?.progress.length === 0 ? '' : program?.progress[0]
            } (bpm)`;
        case 'blood_pressure':
            return `Blood pressure ${
                program?.progress.length === 0 ? '' : program?.progress[0]
            } (mm/hg)`;
        default:
            return `${program.name.replaceAll('_', ' ')} ${
                program.progress[0]
            }`;
    }
}

export default CurrentProgress;
