import {
  RESET_BLOCK_PLANS,
  RESET_FLAGS_PLANS,
  GET_USER_TYPE_REQUEST,
  CREATE_USER_CORELATION_REQUEST,
  GET_MOVEMENT_WORKOUT_REQUEST,
  GET_DETAIL_MOVEMENT_WORKOUT_REQUEST,
  POST_MOVEMENT_WORKOUT_REQUEST,
  DELETE_MOVEMENT_WORKOUT_REQUEST,
  GET_MOVEMENT_WORKOUT_LEVEL_REQUEST,
  GET_DETAIL_MOVEMENT_WORKOUT_LEVEL_REQUEST,
  POST_MOVEMENT_WORKOUT_LEVEL_REQUEST,
  DELETE_MOVEMENT_WORKOUT_LEVEL_REQUEST,
  GET_USER_CORELATION_REQUEST,
  ADD_NEW_USER_CORELATION_REQUEST,
  DELETE_USER_CORELATION_REQUEST,
  UPDATE_MOVEMENT_WORKOUT_LEVEL_REQUEST,
  UPDATE_MOVEMENT_WORKOUT_REQUEST,
  GET_MOVEMENT_WORKOUT_GOAL_REQUEST,
  POST_MOVEMENT_WORKOUT_GOAL_REQUEST,
  UPDATE_MOVEMENT_WORKOUT_GOAL_REQUEST,
  DELETE_MOVEMENT_WORKOUT_GOAL_REQUEST,
  GET_NUTRITION_LEVEL_REQUEST,
  GET_DETAIL_NUTRITION_LEVEL_REQUEST,
  POST_NUTRITION_LEVEL_REQUEST,
  DELETE_NUTRITION_LEVEL_REQUEST,
  GET_NUTRITION_PLAN_REQUEST,
  GET_DETAIL_NUTRITION_PLAN_REQUEST,
  POST_NUTRITION_PLAN_REQUEST,
  DELETE_NUTRITION_PLAN_REQUEST,
  GET_NUTRITION_HABIT_REQUEST,
  GET_DETAIL_NUTRITION_HABIT_REQUEST,
  POST_NUTRITION_HABIT_REQUEST,
  DELETE_NUTRITION_HABIT_REQUEST,
  UPDATE_NUTRITION_HABIT_REQUEST,
  GET_MORNING_MOMENT_REQUEST,
  GET_DETAIL_MORNING_MOMENT_REQUEST,
  UPDATE_MORNING_MOMENT_REQUEST,
  POST_MORNING_MOMENT_REQUEST,
  DELETE_MORNING_MOMENT_REQUEST,
  GET_MOVEMENT_EXERCISE_LIBRARY_CATEGORY_REQUEST,
  ADD_MOVEMENT_EXERCISE_LIBRARY_CATEGORY_REQUEST,
} from '../reducers/PlansReducer';

export const getUserType = () => ({
  type: GET_USER_TYPE_REQUEST,
});

export const createUserCorelation = (payload) => ({
  type: CREATE_USER_CORELATION_REQUEST,
  payload,
});

export const addNewUserCorelation = (payload) => ({
  type: ADD_NEW_USER_CORELATION_REQUEST,
  payload,
});

export const deleteUserCorelation = (payload) => ({
  type: DELETE_USER_CORELATION_REQUEST,
  payload,
});

export const getUserCorelation = () => ({
  type: GET_USER_CORELATION_REQUEST,
});

/***************** Movement Plans *****************/

/* MOVEMENT_WORKOUT */
export const getMovementWorkout = () => ({
  type: GET_MOVEMENT_WORKOUT_REQUEST,
});

export const getMovementWorkoutDetail = (payload) => ({
  type: GET_DETAIL_MOVEMENT_WORKOUT_REQUEST,
  payload,
});

export const addMovementWorkout = (payload) => ({
  type: POST_MOVEMENT_WORKOUT_REQUEST,
  payload,
});

export const updateMovementWorkout = (payload) => ({
  type: UPDATE_MOVEMENT_WORKOUT_REQUEST,
  payload,
});

export const deleteMovementWorkout = (payload) => ({
  type: DELETE_MOVEMENT_WORKOUT_REQUEST,
  payload,
});
/* MOVEMENT_WORKOUT */

/* MOVEMENT_LEVEL */
export const getMovementWorkoutLevel = () => ({
  type: GET_MOVEMENT_WORKOUT_LEVEL_REQUEST,
});

export const getMovementWorkoutLevelDetail = (payload) => ({
  type: GET_DETAIL_MOVEMENT_WORKOUT_LEVEL_REQUEST,
  payload,
});

export const addMovementWorkoutLevel = (payload) => ({
  type: POST_MOVEMENT_WORKOUT_LEVEL_REQUEST,
  payload,
});

export const updateMovementWorkoutLevel = (payload) => ({
  type: UPDATE_MOVEMENT_WORKOUT_LEVEL_REQUEST,
  payload,
});

export const deleteMovementWorkoutLevel = (payload) => ({
  type: DELETE_MOVEMENT_WORKOUT_LEVEL_REQUEST,
  payload,
});
/* MOVEMENT_LEVEL */
/***************** Movement Plans End *****************/

/***************** Movement Goal *****************/

/* MOVEMENT_WORKOUT_GOAL */

export const getMovementWorkoutGoal = () => ({
  type: GET_MOVEMENT_WORKOUT_GOAL_REQUEST,
});

export const addMovementWorkoutGoal = (payload) => ({
  type: POST_MOVEMENT_WORKOUT_GOAL_REQUEST,
  payload,
});

export const updateMovementWorkoutGoal = (payload) => ({
  type: UPDATE_MOVEMENT_WORKOUT_GOAL_REQUEST,
  payload,
});

export const deleteMovementWorkoutGoal = (payload) => ({
  type: DELETE_MOVEMENT_WORKOUT_GOAL_REQUEST,
  payload,
});

/* MOVEMENT_LEVEL_GOAL */

/* MOVEMENT EXERCISE LIBRARY  CATEGORY */
export const addMovementExerciselibraryCategory = (payload) => ({
  type: ADD_MOVEMENT_EXERCISE_LIBRARY_CATEGORY_REQUEST,
  payload,
});
export const getMovementExerciselibraryCategory = () => ({
  type: GET_MOVEMENT_EXERCISE_LIBRARY_CATEGORY_REQUEST,
});
/* MOVEMENT EXERCISE LIBRARY  CATEGORY */

/***************** Movement Goal *****************/

/*
 *
 *
 *
 *
 **************** nutrition level *****************/
/* Nutrition_LEVEL */
export const getNutritionLevel = () => ({
  type: GET_NUTRITION_LEVEL_REQUEST,
});

export const getNutritionLevelDetail = (payload) => ({
  type: GET_DETAIL_NUTRITION_LEVEL_REQUEST,
  payload,
});

export const addNutritionLevel = (payload) => ({
  type: POST_NUTRITION_LEVEL_REQUEST,
  payload,
});

export const deleteNutritionLevel = (payload) => ({
  type: DELETE_NUTRITION_LEVEL_REQUEST,
  payload,
});
/* Nutrition_LEVEL */

/* Nutrition_PLAN */
export const getNutritionPlan = () => ({
  type: GET_NUTRITION_PLAN_REQUEST,
});

export const getNutritionPlanDetail = (payload) => ({
  type: GET_DETAIL_NUTRITION_PLAN_REQUEST,
  payload,
});

export const addNutritionPlan = (payload) => ({
  type: POST_NUTRITION_PLAN_REQUEST,
  payload,
});

export const deleteNutritionPlan = (payload) => ({
  type: DELETE_NUTRITION_PLAN_REQUEST,
  payload,
});
/* Nutrition_PLAN*/

/* Nutrition_Habit */
export const getNutritionHabit = () => ({
  type: GET_NUTRITION_HABIT_REQUEST,
});

export const getNutritionHabitDetail = (payload) => ({
  type: GET_DETAIL_NUTRITION_HABIT_REQUEST,
  payload,
});

export const updateNutritionHabit = (payload) => ({
  type: UPDATE_NUTRITION_HABIT_REQUEST,
  payload,
});

export const addNutritionHabit = (payload) => ({
  type: POST_NUTRITION_HABIT_REQUEST,
  payload,
});

export const deleteNutritionHabit = (payload) => ({
  type: DELETE_NUTRITION_HABIT_REQUEST,
  payload,
});
/* Nutrition_Habit*/
/***************** nutrition level End **************/

/*
 *
 *
 *
 *
 **************** Morning moment Start *****************/

export const getMorningMoment = () => ({
  type: GET_MORNING_MOMENT_REQUEST,
});

export const getMorningMomentDetail = (payload) => ({
  type: GET_DETAIL_MORNING_MOMENT_REQUEST,
  payload,
});

export const updateMorningMoment = (payload) => ({
  type: UPDATE_MORNING_MOMENT_REQUEST,
  payload,
});

export const addMorningMoment = (payload) => ({
  type: POST_MORNING_MOMENT_REQUEST,
  payload,
});

export const deleteMorningMoment = (payload) => ({
  type: DELETE_MORNING_MOMENT_REQUEST,
  payload,
});

/***************** Morning momentEnd **************/

export const resetBlockPlans = (payload) => ({
  type: RESET_BLOCK_PLANS,
  payload,
});

export const resetFlagPlans = (payload) => ({
  type: RESET_FLAGS_PLANS,
  payload,
});
