import React from 'react';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { useDispatch } from 'react-redux';
import { deleteOption, editOption } from '../../redux/actions/QuizzAction';
import InputTitle from '../UI/InputTitle';
import './Quiz.css';

const QuizAns = ({ answer = [], setAnsOption, ansOptions, setAnswerText, isEdit, ansIndex }) => {
  const { text, order, id } = answer;
  const dispatch = useDispatch();

  const handleChangeAnswer = ({ target: { value } }) => {
    if (isEdit) {
      const newAnswers = [...ansOptions];
      newAnswers[ansIndex].text = value;
      setAnsOption(newAnswers);
      return;
    }
    setAnswerText(value);
  };

  const handleDeleteOption = (id) => () => {
    dispatch(deleteOption({ id }));
  };
  const handleUpdateAnswer = () => {
    if (isEdit) {
      dispatch(editOption({ data: { id, text, order } }));
    }
  };
  return (
    <div className={`newVideoWrapper pt-3 borderBottom`}>
      <div className={`d-flex align-item-center`}>
        <InputTitle className={`me-3 mb-3`}>Answer {order || ''}</InputTitle>
      </div>
      <div className={`w-100`}>
        <Form.Group className="mb-3">
          <Form.Control
            type="text"
            placeholder="Answer"
            name="answerText"
            value={text}
            className={`inputQuizAnsWidth`}
            onChange={handleChangeAnswer}
            onBlur={handleUpdateAnswer}
          />
        </Form.Group>
        <div className={`ansDots`}>
          <DropdownButton title="" drop={`start`} className={`menuDots`}>
            <Dropdown.Item href="#" onClick={handleDeleteOption(id)}>
              Delete
            </Dropdown.Item>
            {/*<Dropdown.Item href="#">Action</Dropdown.Item>*/}
            {/*<Dropdown.Item href="#">Another action</Dropdown.Item>*/}
          </DropdownButton>
        </div>
      </div>
    </div>
  );
};
export default QuizAns;
