import React, { useEffect, useState } from 'react';
import './movementWorkout.scss';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import WorkoutLevel from './WorkoutLevel';
import { useDispatch, useSelector } from 'react-redux';
import { addMovementWorkoutLevel, deleteMovementWorkoutLevel } from '../../../../../redux/actions/PlansAction';

const MovementWorkouts = ({ userTypeId }) => {
  const dispatch = useDispatch();
  const {
    movementWorkoutLevel: { data: getWorkoutLevel },
    movementWorkout: { data: movementWorkoutData },
  } = useSelector(({ plan }) => plan);
  const [movementLevel, setMovementLevel] = useState([]);
  const [movementWorkoutList, setMovementWorkoutList] = useState([]);
  const [levelActive, setLevelActive] = useState(1);
  const handleAddLevel = key => {
    setLevelActive(+key);
    // handle add level
    if (key === 'Add level') {
      const currentLevel = movementLevel.results
        ? Math.max.apply(
            Math,
            movementLevel.results.map(function(o) {
              return o.order;
            }),
          ) + 1
        : 1;
      dispatch(
        addMovementWorkoutLevel({
          user_type: userTypeId,
          order: currentLevel === -Infinity ? 1 : currentLevel,
        }),
      );
      return;
    }
    // handle remove level
    if (key === 'Delete level') {
      const currentLevel = Math.max.apply(
        Math,
        movementLevel.results.map(function(o) {
          return o.order;
        }),
      );
      const getId = movementLevel.results.find(i => i.order === levelActive);
      dispatch(deleteMovementWorkoutLevel(getId.id));

      setLevelActive(null);
      return;
    }
  };

  useEffect(() => {
    if (getWorkoutLevel) {
      setMovementLevel(getWorkoutLevel);
    }
    if (movementWorkoutData) {
      setMovementWorkoutList(movementWorkoutData.results);
    }
  }, [getWorkoutLevel, movementWorkoutData]);
  const sortMovementLevel = movementLevel?.results?.sort((a, b) => a.id - b.id);
  return (
    <>
      <div className="plansWorkout">
        <Tabs activeKey={levelActive} id="plansWorkout" onSelect={handleAddLevel}>
          {movementLevel?.results &&
            sortMovementLevel?.map((data, index) => {
              if (data.user_type === userTypeId)
                return (
                  <Tab eventKey={data.order} title={`Level ${data.order}`} key={index}>
                    <WorkoutLevel levelData={data} movementWorkoutList={movementWorkoutList} />
                  </Tab>
                );
            })}
          <Tab eventKey="Add level" title="Add level" tabClassName="tabAddBtn" />
          {!!movementLevel?.results?.length && !!levelActive && (
            <Tab eventKey="Delete level" title="Delete level" tabClassName="tabDeleteBtn" />
          )}
        </Tabs>
      </div>
    </>
  );
};
export default MovementWorkouts;
