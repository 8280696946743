import { all } from 'redux-saga/effects';

import AuthSaga from './AuthSaga';
import UsersSaga from './UsersSaga';
import ExercisesSaga from './ExercisesSaga';
import QuizzSaga from './QuizzSaga';
import PlansSaga from './PlansSaga';
import MovementTypesSaga from './MovementTypesSaga';
import StatisticsSaga from './StatisticsSaga';

export function* sagas() {
  yield all([AuthSaga, UsersSaga, ExercisesSaga, QuizzSaga, PlansSaga, MovementTypesSaga, StatisticsSaga]);
}
