import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import './styles.scss';
const ProgressView = ({ value }) => {
  const theme = useTheme();
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        value={100}
        thickness={1}
        className="fullProgress"
        color={theme.palette.primary.light}
      />

      <CircularProgress variant="determinate" value={value} color="secondary" />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" className="fontBold">
          {value}%
        </Typography>
      </Box>
    </Box>
  );
};
export default ProgressView;
