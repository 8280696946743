import React from 'react';
import { useSelector } from 'react-redux';

import ChartUserActivity from './ChartUserActivity';

import line from '../../assets/images/line1.png';
import messages from '../../assets/icons/messages.svg';
import users from '../../assets/icons/users.svg';
import infoInactive from '../../assets/icons/info-inactive.svg';
import graphInactive from '../../assets/icons/graph-inactive.svg';
import runningFaded from '../../assets/icons/running.svg';

import './stats.css';

export default function Stats() {
  const {
    getTotalData: { data },
  } = useSelector(({ statistics }) => statistics);

  return (
    <div className="container-subNav">
      <ul className="subcontainer-subNav">
        <li className="main">
          <p>New Messages</p>
          <div className="subcard">
            <img className="icon-subcard" src={messages} alt="chat" />
            <span className="data-subNav main">0</span>
          </div>
        </li>
        <li>
          <img src={line} alt="line" />
        </li>
        <li className="main">
          <p>Registered Users</p>
          <div className="subcard">
            <img className="icon-subcard" src={users} alt="users" />
            <span className="data-subNav main">{data.registered_user}</span>
          </div>
        </li>
        <li>
          <img src={line} alt="line" />
        </li>
        <li className="quizz">
          <p>Completed Quizz</p>
          <div className="subcard">
            <img className="icon-subcard" src={infoInactive} alt="information" />
            <span className="data-subNav quizz">{data.completed_quiz}</span>
          </div>
        </li>
        <li>
          <img src={line} alt="line" />
        </li>
        <li className="goals">
          <p>Set Goals</p>
          <div className="subcard">
            <img className="icon-subcard" src={graphInactive} alt="graphics" />
            <span className="data-subNav goals">{data.goals}</span>
          </div>
        </li>
        <li>
          <img src={line} alt="line" />
        </li>
        <li className="movement">
          <p>Movement</p>
          <div className="subcard">
            <img className="icon-subcard" src={runningFaded} alt="graphics" />
            <span className="data-subNav movement">{data.saved_moments}</span>
          </div>
        </li>
        <li>
          <img src={line} alt="line" />
        </li>
        <li>
          <ChartUserActivity
            quizz={data.completed_quiz}
            goals={data.goals}
            movements={data.saved_moments}
          />
        </li>
      </ul>
    </div>
  );
}
