import React from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import ChatMessage from './UsersMessage/ChatMessage';
import { Container } from 'react-bootstrap';

import './styles.scss';

const TabBar = ({ groups, selectedTab, setSelectedTab }) => {
  const onChangeHandler = text => {
    setSelectedTab(text);
  };

  return (
    <div className="userProfileRightContent">
      <Tabs
        onSelect={onChangeHandler}
        activeKey={selectedTab}
        className="tabList"
      >
        {groups.map(group => (
          <Tab eventKey={group.name} title={`${group.name} (${group.members.length})`}>
            <Container>
              <ChatMessage group={group.name} active={group.name === selectedTab} />
            </Container>
          </Tab>
        ))}
      </Tabs>

      {!selectedTab && (
        <div className="noDataWrapper">
          <span className="noDataText">
            Select a community chat to view all messages
          </span>
        </div>
      )}
    </div>
  );
};

export default TabBar;
