import React, { useState } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import './plansUsers.scss';
import PlansUsersLevel from './PlansUsersLevel';

const PlansUsers = () => {
    const [plansUsersLevel, setPlansUsersLevel] = useState([{ id: 1 }]);
    const [levelActive, setLevelActive] = useState(1);
    const [counter, setCounter] = useState(1);
    const handleAddLevel = key => {
        // handle add level
        if (key === 'Add level') {
            const newLevel = {
                id: counter + 1
            };
            setLevelActive(+newLevel.id);
            setPlansUsersLevel(prevState => [...prevState, newLevel]);
            setCounter(prevState => prevState + 1);
            return;
        }
        // handle remove level
        if (key === 'Delete level') {
            const filterData = plansUsersLevel.filter(
                data => +data.id !== +levelActive
            );
            setLevelActive(plansUsersLevel[0].id);
            setPlansUsersLevel(filterData);
            return;
        }
        setLevelActive(+key);
        return;
    };
    return (
        <>
            <div className="plansUsers">
                <Tabs
                    defaultActiveKey={plansUsersLevel[0].id}
                    id="plansUsers"
                    onSelect={handleAddLevel}
                >
                    {plansUsersLevel &&
                        plansUsersLevel.map((data, index) => {
                            return (
                                <Tab
                                    eventKey={data.id}
                                    title={`Level ${data.id}`}
                                    key={index}
                                >
                                    <PlansUsersLevel />
                                </Tab>
                            );
                        })}
                    <Tab
                        eventKey="Add level"
                        title="Add level"
                        tabClassName="tabAddBtn"
                    />
                    {plansUsersLevel.length !== 1 && (
                        <Tab
                            eventKey="Delete level"
                            title="Delete level"
                            tabClassName="tabDeleteBtn"
                        />
                    )}
                </Tabs>
            </div>{' '}
        </>
    );
};
export default PlansUsers;
