import React, { useState, useLayoutEffect, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';

import SendWrapper from './SendWrapper';
import ReceiveWrapper from './ReceiveWrapper';

import Button from '@restart/ui/esm/Button';

import Form from 'react-bootstrap/Form';

import SendIcon from '../../../assets/icons/SendIcon';
import Search from '../../../assets/icons/search.svg';
import placeholder from '../../../assets/images/placeholder1.png';
import './chatMessage.scss';

const ChatMessage = ({ group, active }) => {
  const firestore = useFirestore();
  const areaElement = useRef(null);

  const foundElements = useRef([]);
  let activeElement = useRef(0);

  const [ messages, setMessages ] = useState([]);
  const [ message, setMessage ] = useState('');
  const [ search, setSearch ] = useState('');

  useFirestoreConnect([
    { collection: group }
  ]);

  const profileData = useSelector(state => state.auth.loginUser.data);

  useLayoutEffect(() => {
    if (areaElement.current)
      areaElement.current.scrollTop = areaElement.current.scrollHeight;
  }, [active, messages])

  useLayoutEffect(() => {
    const unsubscribe = firestore
      .collection(group)
      .orderBy('createdAt', 'asc')
      .onSnapshot(snapshot =>
        setMessages(
          snapshot.docs.map(doc => {
            return {
              _id: doc.data()._id,
              createdAt: doc.data().createdAt,
              text: doc.data().text,
              user: doc.data().user
            };
          })
        )
      );
    return unsubscribe;
  }, []);

  const onMessageChangeHandle = event => {
    setMessage(event.target.value);
  }

  const onKeyDownHandle = event => {
    if (event.keyCode === 13) {
      event.preventDefault();
      sendMessage();
    }
  }

  const onSendMessage = event => {
    event.preventDefault();
    sendMessage();
  }

  const sendMessage = () => {
    if (!message.length)
      return false;

    firestore
      .collection(group)
      .add({
        text: message,
        createdAt: firestore.FieldValue.serverTimestamp(),
        user: {
          _id: profileData.email,
          name: profileData.first_name || profileData.username,
          avatar: profileData.profile_picture || placeholder,
        }
    })

    setMessage('')
  }

  const onSearchChangeHandle = event => {
    setSearch(event.target.value)
  }

  const onSearchClickHandle = () => {
    activeElement.current = activeElement.current +1 >= foundElements.current.length ? 0 : activeElement.current +1;
    scrollToFoundElement();
  }

  const onSearchKeyDownHandle = event => {
    if (event.keyCode === 13) {
      event.preventDefault();

      activeElement.current = activeElement.current +1 >= foundElements.current.length ? 0 : activeElement.current +1;
      scrollToFoundElement();
    }
  }

  useEffect(() => {
    const results = Array.from(areaElement.current.getElementsByClassName('search-result'));
    foundElements.current = results.map(result => result.closest('.messageWrapper').offsetTop);

    activeElement.current = 0;
    scrollToFoundElement();
  }, [search]);

  const scrollToFoundElement = () => {
    areaElement.current.scrollTop = foundElements.current[activeElement.current];
  }

  return (
    <>
      <div className="searchInputWrapper">
        <Form.Group className="py-3 inputBoxWrapper">
          <Form.Control
            type="text"
            value={search}
            placeholder="Search in the chat"
            onChange={onSearchChangeHandle}
            onKeyDown={onSearchKeyDownHandle}
            name="search"
          />
          <Button className="p-0 iconAction" onClick={onSearchClickHandle}>
            <img src={Search} alt="search" />
          </Button>
        </Form.Group>
      </div>
      <div className="chatWrapper" ref={areaElement}>
        <div className="chatMessageWrapper">
          {messages.map(message => (
            message.user._id === profileData.email
              ? <SendWrapper message={message} searchQuery={search.length > 1 ? search : ''} key={message.id} />
              : <ReceiveWrapper message={message} searchQuery={search.length > 1 ? search : ''} key={message.id} />
          ))}
        </div>
      </div>
      <Form className="sendMessageWrapper pb-4" onSubmit={onSendMessage}>
        <div className="inputWrapper">
          <span>Message</span>
          <Form.Control value={message} onChange={onMessageChangeHandle} onKeyDown={onKeyDownHandle} as="textarea" required style={{ height: '70px' }} />
        </div>
        <button className="me-3 mt-2">
          <SendIcon />
        </button>
      </Form>
    </>
  );
};

export default ChatMessage;
