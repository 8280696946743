import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import loginCss from '../../pages/Login/loginCss';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../redux/actions/AuthAction';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
const SignIn = () => {
  const classes = loginCss();
  const dispatch = useDispatch();
  const { error, loading } = useSelector((state) => state.auth.login);
  const [authData, setAuthData] = React.useState({
    username: '',
    password: '',
  });
  const [authError, setAuthError] = React.useState({
    username: '',
    password: '',
  });

  const handleChange = ({ target: { name, value } }) => {
    setAuthData({ ...authData, [name]: value });

    if (name === 'password') {
      if (value !== '') {
        setAuthError({
          password: '',
        });
      }
    }

    if (name === 'username') {
      if (value !== '') {
        setAuthError({
          username: '',
        });
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!authData.username) {
      return setAuthError({
        ...authError,
        username: 'Enter username',
      });
    }

    // if (!authData.email) {
    //     return setAuthError({
    //         ...authError,
    //         email: 'Enter email'
    //     });
    // } else {
    //     const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //     if (!re.test(String(authData.email).toLowerCase())) {
    //         return setAuthError({
    //             ...authError,
    //             email: 'Enter valid email'
    //         });
    //     }
    // }

    // if (!authData.password) {
    //     return setAuthError({
    //         ...authError,
    //         password: 'Enter password'
    //     });
    // } else {
    //     let pattern = new RegExp(
    //         /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s)/
    //     );
    //     if (authData.password.length < 8) {
    //         return setAuthError({
    //             ...authError,
    //             password: 'Password length should be minimum 8 character.'
    //         });
    //     } else if (!pattern.test(authData.password)) {
    //         return setAuthError({
    //             ...authError,
    //             password:
    //                 'Please enter minimum 8 characters password combination of uppercase, special character & number.'
    //         });
    //     }
    // }

    dispatch(login(authData));
  };

  return (
    <>
      <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
        <div className={`row`}>
          <div className={`col-12 mb-3`}>
            <TextField
              id="username"
              label="Enter your username"
              name={'username'}
              type={'text'}
              value={authData.username}
              error={authError.username}
              helperText={authError.username}
              onChange={handleChange}
              classes={{
                root: classes.textFieldRoot,
              }}
            />
          </div>
          <div className={`col-12 mb-3`}>
            <TextField
              id="password"
              label="Enter your password"
              name={'password'}
              type={'password'}
              value={authData.password}
              error={authError.password}
              helperText={authError.password}
              onChange={handleChange}
              classes={{
                root: classes.textFieldRoot,
              }}
            />
          </div>
          {error && <Alert severity="error">{error}</Alert>}
          <div className={`col-12 mb-3 pt-2 d-flex`}>
            <Button variant="contained" type={`submit`} disabled={loading}>
              {loading ? 'Login....' : 'Login'}
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};
export default SignIn;
