import React from 'react';

import Col from 'react-bootstrap/Col';

import circleBody from '../../../assets/icons/circle-body.png';
import graphActive from '../../../assets/icons/graph-active.svg';
import infoActive from '../../../assets/icons/info-active.svg';
import runningActive from '../../../assets/icons/running-active.svg';
import placeholder from '../../../assets/images/placeholder1.png';

import './userCards.css';

export default function UserCards({ data }) {
  return (
    <div className="card-container">
      <Col className="icon-section">
        <img className="icon-info" src={infoActive} alt="info" />
        <img className="icon-graph" src={graphActive} alt="graph" />
        <img className="icon-running" src={runningActive} alt="running" />
      </Col>
      <Col className="user-section">
        <div className="user-name">
          <p>{data.first_name} {data.last_name}</p>
        </div>

        <div className="card-main">
          <img className="user-picture" src={placeholder} alt="picture" />
          <img className="user-plans" src={circleBody} alt="body" />
        </div>
      </Col>
    </div>
  );
}
