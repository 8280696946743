import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { firebaseReducer } from 'react-redux-firebase'
import { firestoreReducer } from 'redux-firestore'

// we will connect our reducers here
import { AuthReducer } from './AuthReducer';
import { UsersReducer } from './UsersReducer';
import { ExercisesReducer } from './ExercisesReducer';
import { QuizzReducer } from './QuizzReducer';
import { PlansReducer } from './PlansReducer';
import { MovementTypesReducer } from './MovementTypesReducer';
import { StatisticsReducer } from './StatisticsReducer';

const appReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth: AuthReducer,
    users: UsersReducer,
    exercises: ExercisesReducer,
    quizz: QuizzReducer,
    plan: PlansReducer,
    movementTypes: MovementTypesReducer,
    statistics: StatisticsReducer,
    firebase: firebaseReducer,
    firestore: firestoreReducer
  });

const createRootReducer = (history) => (state, action) => {
  if (action.type === 'LOGOUT') {
    state = undefined;
  }
  return appReducer(history)(state, action);
};

export default createRootReducer;
